import { organisationTypesWithControllingPerson } from "../../shared/datalists/organisation-type";
import { IndberetningstypeAwareness } from "../../shared/helpers/Indberetningstype-awareness";
import { AccountReportModel } from './account-report.model';
import { AddressModel } from './address.model';
import { ControllingPersonModel } from './controlling-person.model';
import { IdNumberModel } from "./id-number.model";

export class EnkeltindberetningModel {

    // Shared CRS and FATCA reporter fields
    name: string;
    address: AddressModel;
    docTypeIndic: string = IndberetningstypeAwareness.statics.erFatca() ? "FATCA1" : "OECD1";
    docRefIdPrefix: string = "";
    docRefIdValue: string = "";
    accountReport: AccountReportModel;

    // FATCA specific reporter fields
    giin: string = "";
    filerCategory: string = "";

    constructor() {
        this.init();
    }

    init() {
        this.accountReport = new AccountReportModel();
        this.address = new AddressModel();
        this.address.countryCode = 'DK';
    }

    get accountHolderAdresses(): AddressModel[] {
        return this.accountReport.erPerson() ? this.accountReport.individualAccountHolder.addresses :
            this.accountReport.organisationAccountHolder.addresses;
    }

    get accountResidentialCountries(): string[] {
        return this.accountReport.erPerson() ? this.accountReport.individualAccountHolder.resCountryCodes :
            this.accountReport.organisationAccountHolder.resCountryCodes;
    }

    get idNumbers(): IdNumberModel[] {
        return this.accountReport.erPerson() ? this.accountReport.individualAccountHolder.idNumbers :
            this.accountReport.organisationAccountHolder.idNumbers;
    }

    get controllingPersons(): ControllingPersonModel[] {
        return this.accountReport.controllingPersons;
    }

    // Side-effect: Updates the model's hasControllingPersons property
    hasControllingPersons(): boolean {
        const hasControllingPersons = this.accountReport.erVirksomhed() &&
                organisationTypesWithControllingPerson.includes(this.accountReport.organisationAccountHolder.organisationType);
        this.accountReport.hasControllingPersons = hasControllingPersons;
        return hasControllingPersons;
    }
}
