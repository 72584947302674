import { Injectable } from "@angular/core";
import { Fejl } from "../../../shared/models/fejl.model";
import { isDefinedAndNotEmpty } from "../../../shared/utils/text-utils";
import { ControllingPersonModel } from "../../models/controlling-person.model";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { IdNumberValidator } from "../../models/id-number.validator";
import { IndividualAccountHolderValidator } from "../../models/individual-account-holder.validator";
import { EnkeltindberetningValidator } from "./enkeltindberetning-validator.interface";

@Injectable()
export class KontrollerendePersonValidatorService implements EnkeltindberetningValidator {

    validate(model: EnkeltindberetningModel, erFatca: boolean): Fejl[] {
        let errors: Fejl[] = [];
        const controllingPersons = model.controllingPersons;

        // Validate the person
        controllingPersons.forEach((controllingPerson: ControllingPersonModel) => {
            errors = errors.concat(
                IndividualAccountHolderValidator.validate(controllingPerson.individualAccountHolder)
            );
        });
        if (erFatca) {
            // Validate ID (TIN) numbers
            controllingPersons.forEach((controllingPerson: ControllingPersonModel) => {
                errors = errors.concat(
                    IdNumberValidator.validateFatcaIndividualIdNumbers(controllingPerson.individualAccountHolder.idNumbers)
                );
            });
        } else {
            // Validate controlling person types
            controllingPersons.forEach((controllingPerson: ControllingPersonModel) => {
                errors = errors.concat(
                    KontrollerendePersonValidatorService.validateCtrlgPersonType(controllingPerson.ctrlgPersonType)
                );
            });
        }
        return errors;
    }

    private static validateCtrlgPersonType(ctrlgPersonType: string): Fejl[] {
        if (!isDefinedAndNotEmpty(ctrlgPersonType)) {
            return [new Fejl("CtrlgPersonType skal udfyldes")]; // TODO Indsæt fejltekst-nøgle
        }
        return [];
    }
}
