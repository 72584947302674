import { AccountHolderModel } from './account-holder.model';

export class OrganisationAccountHolderModel extends AccountHolderModel {
    name: string;
    organisationType: string = "";

    constructor() {
        super();
    }

    presentationName(): string {
        return this.name;
    }
}
