import { Fejl } from "../../shared/models/fejl.model";
import { validTin } from "../../shared/utils/validation-utils";
import { IdNumberModel } from "./id-number.model";

export class IdNumberValidator {

    static validateIssuedBy(idNumbers: IdNumberModel[]): Fejl[] {
        if (idNumbers.some(idNumber =>
            idNumber.value.trim() !== "" && (idNumber.issuedBy.trim() === "" || idNumber.issuedBy == null)
        )) {
            return [new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.ID_LAND_PAAKRAVET")];
        }
        return [];
    }

    static validateFatcaIndividualIdNumbers(idNumbers: IdNumberModel[]): Fejl[] {
        if (idNumbers.some(idNumber => idNumber.issuedBy === "US" && !validTin(idNumber.value))) {
            return [new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.FATCA_INVALID_TIN")];
        }
        return [];
    }

}
