export class Fejl {
    fejlkode: string;
    fejltekst: string;

    constructor(besked: string, kode: string = null) {
        this.fejlkode = kode;
        this.fejltekst = besked;
    }

    text(): string {
        return this.fejlkode ? this.fejlkode + ": " + this.fejltekst : this.fejltekst;
    }
}
