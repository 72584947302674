import { IndividualAccountHolderModel } from './individual-account-holder.model';

export class ControllingPersonModel {

    individualAccountHolder: IndividualAccountHolderModel;
    ctrlgPersonType: string = "";

    constructor() {
        this.init();
    }

    init() {
        this.individualAccountHolder = new IndividualAccountHolderModel();
    }

    presentationName(): string {
        return this.individualAccountHolder.presentationName();
    }
}
