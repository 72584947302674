import { EnkeltindberetningModel } from "../../enkeltindberetning/models/enkeltindberetning.model";

export const INDBERETNING_TYPE_KEY:string = "Indberetningstype";
export const PLIGTIG_KEY:string = "pligtig";
export const YEAR:string = "reportingYear";
export const MSG_REF:string = "msgRef";
export const ER_SUPPLERENDE:string = "ErSupplerende";
export const FI_FOR_SUPPLERENDE:string = "FIForSupplerende";
export const INDBERETTER_KEY:string = "indberetter";
export const ENKELTINDBERETNING_KEY: string = "enkeltindberetning";
export const PLIGTIG_VALIDATING_KEY:string = "pligtig_validating";
export const RECENT_ENKELTINDBERETNING_KEY:string = "recent_enkeltindberetning";
export const LANGUAGE_CODE_KEY: string = "language_code";
export const TEXT_KEYS_VISIBLE:string = "false";

export function getIndberetningstype():string {
    return window.sessionStorage.getItem(INDBERETNING_TYPE_KEY);
}
export function setIndberetningstype(type:string) {
    window.sessionStorage.setItem(INDBERETNING_TYPE_KEY, type);
}

export function getPligtig():string {
    return window.sessionStorage.getItem(PLIGTIG_KEY);
}
export function setPligtig(pligtig:string) {
    window.sessionStorage.setItem(PLIGTIG_KEY, pligtig);
}

export function getIndberetter():string {
    return window.sessionStorage.getItem(INDBERETTER_KEY);
}
export function setIndberetter(pligtig:string) {
    window.sessionStorage.setItem(INDBERETTER_KEY, pligtig);
}
export function clearPligtigOgIndberetter() {
    window.sessionStorage.removeItem(PLIGTIG_KEY);
    window.sessionStorage.removeItem(INDBERETTER_KEY);
}

export function userIsBeingValidated(): boolean {
    return window.sessionStorage.getItem(PLIGTIG_VALIDATING_KEY) === "true";
}

export function setUserIsBeingValidated(isValidating:boolean) {
    window.sessionStorage.setItem(PLIGTIG_VALIDATING_KEY, isValidating.toString());
}

export function indberetterAndPligtigAreTheSame():boolean {
    const pligtig = getPligtig();
    return pligtig !== null && pligtig === getIndberetter();
}

export function waitForPligtigAndPerformAction(action: any, failedAction: any, maxTries: number = 10, tryCount: number = 0) {
    if (getPligtig() != null && !userIsBeingValidated()) {
        action();
    } else {
        if (tryCount < maxTries) {
            setTimeout((_: any) => {
                waitForPligtigAndPerformAction(action, maxTries, tryCount + 1);
            }, 500);
        } else {
            failedAction();
        }
    }
}

export function clearEnkeltindberetningFields() {
    window.sessionStorage.removeItem(ENKELTINDBERETNING_KEY);
    window.sessionStorage.removeItem(YEAR);
    window.sessionStorage.removeItem(MSG_REF);
    window.sessionStorage.removeItem(ER_SUPPLERENDE);
    window.sessionStorage.removeItem(FI_FOR_SUPPLERENDE);
}

export function setEnkeltindberetningModel(model: EnkeltindberetningModel) {
    window.sessionStorage.setItem(ENKELTINDBERETNING_KEY, JSON.stringify(model));
}

export function getEnkeltingberetningModel(): string {
    return window.sessionStorage.getItem(ENKELTINDBERETNING_KEY);
}

export function clearRecentSubmissions() {
    window.sessionStorage.removeItem(RECENT_ENKELTINDBERETNING_KEY);
}
export function getRecentSubmissions(): string {
    return window.sessionStorage.getItem(RECENT_ENKELTINDBERETNING_KEY);
}
export function setRecentSubmissions(value: string) {
    window.sessionStorage.setItem(RECENT_ENKELTINDBERETNING_KEY, value);
}

export function getReportingYear():string {
    return window.sessionStorage.getItem(YEAR);
}

export function setReportingYear(year:string) {
    window.sessionStorage.setItem(YEAR, year);
}

export function getMsgRef():string {
    return window.sessionStorage.getItem(MSG_REF);
}

export function setMsgRef(msgRef:string) {
    window.sessionStorage.setItem(MSG_REF, msgRef);
}

export function getErSupplerende():boolean {
    return window.sessionStorage.getItem(ER_SUPPLERENDE) === "true";
}

export function setErSupplerende(suppl:boolean) {
    window.sessionStorage.setItem(ER_SUPPLERENDE, suppl ? "true" : "false");
}

export function getFIForSupplerende():string {
    return window.sessionStorage.getItem(FI_FOR_SUPPLERENDE);
}

export function setFIForSupplerende(suppl:string) {
    window.sessionStorage.setItem(FI_FOR_SUPPLERENDE, suppl);
}

export function setLanguageCode(languageCode: string) {
    window.sessionStorage.setItem(LANGUAGE_CODE_KEY, languageCode);
}

export function getLanguageCode(): string {
    return window.sessionStorage.getItem(LANGUAGE_CODE_KEY);
}

export function setTextKeysVisible(active:string) {
    window.sessionStorage.setItem(TEXT_KEYS_VISIBLE, active);
}

export function textKeysVisible():boolean {
    return window.sessionStorage.getItem(TEXT_KEYS_VISIBLE) !== "false";
}
