
export class RecentIndberetningModel {
    date: string;
    status: string;
    reportingYear: string;
    name: string;
    accountNumber: string;
    arDocRefId: string;
    reportingId: number;
    statusLink: string;
    hasControllingPersons: boolean;
    controllingPersonNames: string[] = [];
}
