import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { Fejl } from "../../../shared/models/fejl.model";
import {
    genererUrlTilOversigt,
    genererUrlTilOversigtMedOffset,
    genererUrlTilProcesseringsstatus
} from '../../../shared/utils/url-utils';
import { GetRecordsModel } from '../../models/get-records.model';
import { HistoryRecordModel } from "../../models/history-record.model";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class HentHistorikService {

    reportListCompleted: Subject<GetRecordsModel> = new Subject<GetRecordsModel>();
    reportsProcessingCompleted: Subject<boolean> = new Subject<boolean>();
    responseError: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    public getReports() {
        const path: string = genererUrlTilOversigt();
        this.http.get<any>(path, httpOptions)
            .subscribe(
                (res: any) => {
                    this.extractData(res);
                },
                (error: any) => {
                    this.handleError();
                }
            );
    }

    public getReportsOnPage(offset: number, pageSize: number, yearFilterValue: string, messageRefIdFilterValue: string, statusFilterValue: string) {
        const path: string = genererUrlTilOversigtMedOffset(offset, pageSize, yearFilterValue, messageRefIdFilterValue, statusFilterValue);
        this.http.get<any>(path, httpOptions)
            .subscribe(
                (res: any) => {
                    this.extractData(res);
                },
                (error: any) => {
                    this.handleError();
                }
            );
    }

    public getReportsProcessing() {
        const path: string = genererUrlTilProcesseringsstatus();

        this.http.get(path, httpOptions)
            .subscribe(
                (res: any) => {
                    this.extractProcessingStatus(res);
                },
                error => {
                    this.handleError();
                }
            );
    }

    private extractData(body: any) {
        const records: HistoryRecordModel[] = [];

        if (body.data && Object.keys(body.data).length > 0) {
            for (const element of Object.values(body.data)) {
                const statusLink: string = this.getStatusLink(element);
                const reportingLink: string = this.getReportingLink(element);
                records.push(this.buildHistoryRecordModel(element, statusLink, reportingLink));
            }
        }

        this.reportListCompleted.next({
            records: records,
            totalCount: body.totalCount,
            totalPages: body.totalPages,
            offset: body.offset,
            pageSize: body.pageSize
        });
    }

    private extractProcessingStatus(body: any) {
        let result: any = null;
        if (body.data && Object.keys(body.data).length > 0) {
            result = body.data.reportingsProcessing;
        }
        this.reportsProcessingCompleted.next(result);
    }

    private getStatusLink(element: any): string {
        return (element.relationships.hasOwnProperty("receipt")) ? element.relationships.receipt.links.related : "";
    }

    private getReportingLink(element: any): string {
        return (element.relationships.hasOwnProperty("reporting")) ? element.relationships.reporting.links.related : "";
    }

    private buildHistoryRecordModel(element: any, statusLink: string, reportingLink: string): HistoryRecordModel {

        return new HistoryRecordModel(
            element.id,
            element.attributes.messageRefId,
            element.attributes.fiDocRefId,
            element.attributes.fiName,
            element.attributes.date,
            element.attributes.reportingYear,
            element.attributes.status,
            statusLink,
            reportingLink
        );
    }

    private handleError() {
        this.responseError.next(new Fejl('OVERSIGT.HENTEFEJL'));
    }
}
