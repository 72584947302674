import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { nyStandardFejl } from "../../utils/fejl-utils";
import { getIndberetter, getPligtig } from "../../utils/session-storage-utils";
import { configUrl } from "../../utils/url-utils";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class ConfigService {

    router: Router;

    loginInfo: Subject<any> = new Subject<any>();
    loginInfoFejl: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    hentConfig() {
        this.http.get<any>(configUrl(), httpOptions)
            .subscribe(
                (json: any) => {
                    const nyPligtig: boolean = json.pligtigSeNummer && json.pligtigSeNummer !== getPligtig();
                    const nyIndberetter: boolean = json.indberetterSeNummer && json.indberetterSeNummer !== getIndberetter();

                    if (nyPligtig || nyIndberetter) {
                        this.loginInfo.next(json);
                    }

                }, (error: any) => {
                    if (getPligtig() == null) {
                        // We don't want to expose any internal errors, so just add generic error
                        this.loginInfoFejl.next(nyStandardFejl());
                    }
                }
            );
    }
}
