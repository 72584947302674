import { Component } from '@angular/core';
import { TitleService } from '../shared/services/title/title.service';
import { TranslationService } from "../shared/services/translate/translation.service";

@Component({
    selector: 'skat-forside',
    templateUrl: './forside.component.html',
})
export class ForsideComponent {

    constructor(private translationService:TranslationService, private titleService: TitleService) {
        page.showLoader("Initialiserer Applikationen...");
        this.translationService.environmentAwareTranslate('NULINDBERETNING.INDBERET.SPINNER').subscribe((res: string) => {
            page.hideLoader();
        });
    }

    overskrift(): string {
        return this.titleService.setTitleToReportingType();
    }
}
