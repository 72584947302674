import { OverviewItemModel } from "./overview-item.model";

export class KontoejerModel extends OverviewItemModel {
    arDocRefId: string;
    accountHolderName: string;
    accountNumber: string;
    controllingPersonNames: string[];
    substantialOwnerNames: string[];

    cpNames(): string[] {
        return [].concat(this.controllingPersonNames, this.substantialOwnerNames);
    }
}
