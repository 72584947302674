import { Injectable } from "@angular/core";
import { Fejl } from "../../../shared/models/fejl.model";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { EnkeltIndberetningValidatorFatca } from "./enkeltindberetning-validator-fatca";
import { EnkeltindberetningValidator } from "./enkeltindberetning-validator.interface";

@Injectable()
export class EnkeltIndberetningValidatorService implements EnkeltindberetningValidator {

    validate(model: EnkeltindberetningModel, erFatca: boolean): Fejl[] {
        const errors: Fejl[] = [];
        if (erFatca) {
            return errors.concat(EnkeltIndberetningValidatorFatca.validate(model));
        }
        return errors;
    }
}
