import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { generateErrorItems } from "../../../shared/utils/fejl-utils";
import { splitCrsDocRefId } from "../../../shared/utils/text-utils";
import { genererUrlTilFIListe } from "../../../shared/utils/url-utils";
import { AddressModel } from "../../models/address.model";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class HentFiListeService {

    fetchCompleted: Subject<any> = new Subject<any>();
    fetchCompletedWithErrors: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    hentFI(periode: string) {
        const url = genererUrlTilFIListe(periode);
        this.http.get(url, httpOptions )
            .subscribe((json: any) => {
                this.extractData(json);
            }, (error: any) => {
                const errors = generateErrorItems(error._body, error.status);
                this.fetchCompletedWithErrors.next(errors);
            });

    }

    extractData(json: any) {
        const map = new Map<string, EnkeltindberetningModel>();
        if (json.reportingFis) {
            for (const fi of json.reportingFis) {
                const model = new EnkeltindberetningModel();
                model.name = fi.name;
                model.docTypeIndic = "OECD0";
                const splitDocRef = splitCrsDocRefId(fi.docRefId);
                model.docRefIdPrefix = splitDocRef.prefix;
                model.docRefIdValue = splitDocRef.value;

                model.address = new AddressModel();
                model.address.buildingIdentifier = fi.buildingIdentifier;
                model.address.street = fi.street;
                model.address.countryCode = fi.countryCode;
                model.address.suiteIdentifier = fi.suiteIdentifier;
                model.address.pob = fi.pob;
                model.address.postCode = fi.postCode;
                model.address.floorIdentifier = fi.floorIdentifier;
                model.address.city = fi.city;

                map.set(fi.docRefId, model);
            }
        }

        this.fetchCompleted.next(map);
    }
}
