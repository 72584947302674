import { Fejl } from "../models/fejl.model";

export function nyStandardFejl(): Fejl {
    return new Fejl(standardFejlbesked());
}

export function standardOverskrift(): string {
    return "GENERELT.FEJL.STANDARD_OVERSKRIFT";
}

export function standardFejlbesked(): string {
    return "GENERELT.FEJL.STANDARD_FEJLBESKED";
}

export function schemaFejlOverskrift(): string {
    return "GENERELT.FEJL.SCHEMAVALIDERING_OVERSKRIFT";
}

export function nyRettighedsFejl(): Fejl {
    return new Fejl("GENERELT.FEJL.RETTIGHED_FEJLBESKED");
}

export function generateErrorItems(response: any, status: any): Fejl[] {
    const errors = [];
    if (status === 403) {
        errors.push(nyRettighedsFejl());
    }
    if (response !== "" && response != null && isJson(response)) {
        const json = JSON.parse(response);

        if (status === 400 && json.errors.length > 0) {
            for (const error of json.errors) {
                if (error.code && error.details) {
                    errors.push(new Fejl(error.details, error.code.toString()));
                }
            }

        }
    }
    if (errors.length === 0) {
        errors.push(nyStandardFejl());
    }
    return errors;
}

export function isJson(text:any) {
    let tmp = typeof text !== "string" ? JSON.stringify(text) : text;
    try {
        tmp = JSON.parse(tmp);
    } catch (e) {
        return false;
    }
    return typeof tmp === "object" && tmp !== null;
}
