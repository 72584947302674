import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { generateErrorItems } from "../../../shared/utils/fejl-utils";
import { splitTextAndGetLast } from "../../../shared/utils/text-utils";
import { genererUrlTilNulindberetning } from "../../../shared/utils/url-utils";
import { NulindberetningModel } from "../../models/nulindberetning.model";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class NulindberetningService {

    indberetningCompleted: Subject<any> = new Subject<any>();
    indberetningCompletedWithErrors: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    nulIndberet(model:NulindberetningModel) {
          this.http.post(genererUrlTilNulindberetning(), model, httpOptions)
            .subscribe((res: any) => {
                model.oprettelsesid = this.parseId(res);
                this.indberetningCompleted.next();
            }, (error: any) => {
                this.handleErrors(error.message, error.status);
            });
    }

    public handleErrors(errors: Response, status: any) {
        const errormessages = generateErrorItems(errors, status);
        this.indberetningCompletedWithErrors.next(errormessages);
    }

    public parseId(json: any): number {
        if (json.data && json.data.relationships) {
            const resource:string = json.data.relationships.reporting.links.related;
            const idText:string = splitTextAndGetLast(resource, "/");
            return Number.parseInt(idText);
        }
        return 0;
    }
}
