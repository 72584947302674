import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: "skat-navbar",
    templateUrl: "navbar.component.html",
    providers: []
})

export class NavbarComponent {

    stepArray: Array<number>;

    @Input() activeStep: number;

    @Input()
    set withKontrollerendePerson(showIt: boolean) {
        this.stepArray = this.procesTitler
            .map((val, idx) => idx)
            .filter(idx => showIt || idx !== 2);
    }

    procesTitler: Array<string> = [
        "GENERELT.NAVBAR.INDBERETNING",
        "GENERELT.NAVBAR.KONTOEJER",
        "GENERELT.NAVBAR.KONTROLLERENDEPERSONER",
        "GENERELT.NAVBAR.MODTAGET"
    ];
}
