import { Component, Input } from '@angular/core';

@Component({
    selector: "skat-hjaelpetekst",
    templateUrl: "./hjaelpetekst.component.html"
})

export class HjaelpetekstComponent {

    @Input() guid:string;

    linkTilTekst:string;

    constructor() {}

    ngOnInit() {
        this.linkTilTekst = "https://info.skat.dk/SKAT.aspx?oID=" + this.guid + "&layout=2503";
    }
}
