import { AfterViewInit, Component, ElementRef, Injectable, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { accountNumberTypes } from "../../../shared/datalists/account-number-type";
import { currencies } from "../../../shared/datalists/currency";
import { organisationTypesCrs, organisationTypesFatca } from "../../../shared/datalists/organisation-type";
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { CountryModel } from "../../../shared/models/country-model";
import { Fejl } from "../../../shared/models/fejl.model";
import * as ModelConstants from "../../../shared/models/model-constants";
import { CountryService } from "../../../shared/services/country/country.service";
import { ErrorService } from "../../../shared/services/error/error.service";
import { FormSubmissionService } from "../../../shared/services/form-submission/form-submission.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { saveRecentlySubmittedEnkeltIndberetning } from '../../../shared/utils/recent-indberetninger-helper';
import { waitForPligtigAndPerformAction } from "../../../shared/utils/session-storage-utils";
import { genererUrlTilEnkeltindberetning } from "../../../shared/utils/url-utils";
import { AccountHolderType } from "../../models/account-holder-type";
import { AddressModel } from "../../models/address.model";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { IdNumberModel } from "../../models/id-number.model";
import { EnkeltindberetningFlowService } from "../../services/enkeltindberetning-flow/enkeltindberetning-flow.service";
import { KontoEjerValidatorService } from "../../services/validation/kontoejer-validator.service";

@Component({
    templateUrl: './enkeltindberetning-kontoejer.component.html',
    providers: [FormSubmissionService, KontoEjerValidatorService],
    styleUrls: ['../../../shared/css/repeterende-grupper.css']
})
@Injectable()
export class EnkeltindberetningKontoejerComponent extends IndberetningstypeAwareness implements OnInit, AfterViewInit {

    model: EnkeltindberetningModel;
    AccountHolderType: typeof AccountHolderType = AccountHolderType;

    private controllingPersonUrl = "enkeltindberetning/kontrollerende-person";
    private successUrl = "/enkeltindberetning/modtaget";

    flow: EnkeltindberetningFlowService;

    @ViewChild('initialFocus') initialFocusElement: ElementRef;

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private formSubmissionService: FormSubmissionService,
                public errors: ErrorService,
                private validator: KontoEjerValidatorService,
                private enkeltindberetningFlowService: EnkeltindberetningFlowService,
                private titleService: TitleService,
                private countryService: CountryService) {
        super();
        this.flow = enkeltindberetningFlowService;
        this.updateModel();
        if (this.flow.reportingYear === "" || this.model.name === "") {
            router.navigate(["/enkeltindberetning"]);
        }
        formSubmissionService.backendUrlFunc = genererUrlTilEnkeltindberetning;

    }

    private updateModel() {
        this.model = this.flow.getCurrentModel();
        if (this.erFatca()) {
            this.model.idNumbers.forEach((x) => {
                if (!x.issuedBy) {
                    x.issuedBy = 'US';
                }
            });
        }
        this.generateRandomId();
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    private generateRandomId() {
        if (!this.model.accountReport.docRefIdValue) {
            this.model.accountReport.docRefIdValue = EnkeltindberetningFlowService.constructUUID();
        }
    }

    ngOnInit(): void {
        this.formSubmissionService.init();
        this.errors.reset();
        this.subscribePaaSuccess();
        this.subscribePaaFejl();

        waitForPligtigAndPerformAction(() => {
            this.model.accountReport.docRefIdPrefix = this.flow.constructDocRefIdPrefix(this.erFatca(), "AR", this.model.giin);
            page.hideLoader();
        }, () => { });
    }

    ngOnDestroy() {
        this.flow.saveInitielModel(this.model);
    }

    ngAfterViewInit() {
        this.initialFocusElement.nativeElement.focus();
    }

    submit() {
        this.flow.saveInitielModel(this.model);
        this.errors.reset();

        const modelToSend: any = this.model;
        if (this.hasControllingPersons()) {
            // Validate and navigate to the controlling person page
            this.formSubmissionService.validateAndNavigate(this.validator, modelToSend, this.erFatca());
        } else {
            // Validate and submit reporting
            modelToSend.reportingYear = this.flow.reportingYear;
            modelToSend.messageRefIdPrefix = this.flow.messageRefIdPrefix;
            modelToSend.messageRefIdValue = this.flow.messageRefIdValue;
            this.formSubmissionService.validateAndPost(this.validator, modelToSend, this.erFatca());
        }
    }

    changeAccHolderType(): void {
        if (this.erFatca() && !this.model.idNumbers[0].issuedBy) {
            this.model.idNumbers[0].issuedBy = 'US';
        }
    }

    hasControllingPersons(): boolean {
        return this.model.hasControllingPersons();
    }

    kontoejertypeWasSelected() {
        if (!this.hasControllingPersons()) {
            this.resetInvalidOrganisationResCountries();
        }
    }

    private resetInvalidOrganisationResCountries() {
        const validResCountryCodes = this.validOrganisationResCountries().map(country => country.countryCode);
        const modelResCountryCodes = this.model.accountReport.organisationAccountHolder.resCountryCodes;
        const fallbackValue = this.erFatca() ? 'US' : '';
        this.model.accountReport.organisationAccountHolder.resCountryCodes = modelResCountryCodes.map((countryCode: string) => {
            return validResCountryCodes.includes(countryCode) ? countryCode : fallbackValue;
        });
    }

    addId() {
        const idNumberModel = new IdNumberModel();
        if (this.erFatca()) {
            idNumberModel.issuedBy = 'US';
        }
        this.model.idNumbers.push(idNumberModel);
    }

    removeId(index: number) {
        this.model.idNumbers.splice(index, 1);
    }

    addAdress() {
        this.model.accountHolderAdresses.push(new AddressModel());
    }

    addBopaelsland() {
        this.model.accountResidentialCountries.push("");
    }

    removeBopaelsland(index: number) {
        this.model.accountResidentialCountries.splice(index, 1);
    }

    subscribePaaSuccess() {
        this.formSubmissionService.completed.subscribe(
            (svar: any) => {
                if (this.hasControllingPersons()) {
                    this.router.navigate([this.controllingPersonUrl]);
                } else {
                    saveRecentlySubmittedEnkeltIndberetning(this.model, this.flow.reportingYear, svar.data.attributes.reportingId);
                    page.hideLoader();
                    this.router.navigate([this.successUrl]);
                }
            }
        );
    }

    subscribePaaFejl() {
        this.formSubmissionService.completedWithErrors.subscribe(
            (fejl: Fejl[]) => {
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
            }
        );
    }

    accountNumberTypes() {
        return accountNumberTypes;
    }

    validOrganisationResCountries(): CountryModel[] {
        if (this.hasControllingPersons()) {
            return this.countries();
        } else {
            return this.erFatca() ? this.fatcaCountries() : this.crsCountries();
        }
    }

    countries(): CountryModel[] {
        return this.countryService.getCountries();
    }

    crsCountries(): CountryModel[] {
        return this.countryService.crsCountries();
    }

    fatcaCountries(): CountryModel[] {
        return this.countryService.fatcaCountries();
    }

    currencies() {
        return currencies;
    }

    organisationTypesCrs() {
        return organisationTypesCrs;
    }

    organisationTypesFatca() {
        return organisationTypesFatca;
    }

    tilbage() {
        this.router.navigate(["/enkeltindberetning", {
            persist: "true"
        }]);
    }

    docRefIdValueLength(): number {
        return this.erFatca() ? ModelConstants.fatcaDocRefIdValueLength : ModelConstants.crsDocRefIdValueLength;
    }

    maxStringLength(): number {
        return ModelConstants.maxStringLength;
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('ENKELTINDBERETNING.INDBERETNING.OVERSKRIFT');
    }
}
