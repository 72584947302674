import { Component, Input } from '@angular/core';
import { Fejl } from "../../../models/fejl.model";
import { ErrorService } from "../../../services/error/error.service";
import { TitleService } from '../../../services/title/title.service';

@Component({
    selector: "skat-fejl-liste",
    templateUrl: "./fejl-liste.component.html"
})

export class FejlListeComponent {

    fejl: Fejl[];

    @Input()
    titel: string;

    constructor(private errorService: ErrorService, private titleService: TitleService) {
        this.update();
        errorService.newErrors.subscribe((nyeFejl: any) => {
            this.update();
        });
    }

    update() {
        this.fejl = this.errorService.alleFejl();
    }

    overskrift():string {
        return this.titleService.setTitleFromKey(this.titel ? this.titel : 'GENERELT.FEJL.STANDARD_OVERSKRIFT', undefined);
    }
}
