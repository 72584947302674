export const currencies = [
    { "value": "AED", "key": "AED: UAE Dirham"},
    { "value": "AFN", "key": "AFN: Afghani"},
    { "value": "ALL", "key": "ALL: Lek"},
    { "value": "AMD", "key": "AMD: Armenian Dram"},
    { "value": "ANG", "key": "ANG: Netherlands Antillean Guilder"},
    { "value": "AOA", "key": "AOA: Kwanza"},
    { "value": "ARS", "key": "ARS: Argentine Peso"},
    { "value": "AUD", "key": "AUD: Australian Dollar"},
    { "value": "AWG", "key": "AWG: Aruban Florin"},
    { "value": "AZN", "key": "AZN: Azerbaijanian Manat"},
    { "value": "BAM", "key": "BAM: Convertible Mark"},
    { "value": "BBD", "key": "BBD: Barbados Dollar"},
    { "value": "BDT", "key": "BDT: Taka"},
    { "value": "BGN", "key": "BGN: Bulgarian Lev"},
    { "value": "BHD", "key": "BHD: Bahraini Dinar"},
    { "value": "BIF", "key": "BIF: Burundi Franc"},
    { "value": "BMD", "key": "BMD: Bermudian Dollar"},
    { "value": "BND", "key": "BND: Brunei Dollar"},
    { "value": "BOB", "key": "BOB: Boliviano"},
    { "value": "BOV", "key": "BOV: Mvdol"},
    { "value": "BRL", "key": "BRL: Brazilian Real"},
    { "value": "BSD", "key": "BSD: Bahamian Dollar"},
    { "value": "BTN", "key": "BTN: Ngultrum"},
    { "value": "BWP", "key": "BWP: Pula"},
    { "value": "BYR", "key": "BYR: Belarussian Ruble"},
    { "value": "BZD", "key": "BZD: Belize Dollar"},
    { "value": "CAD", "key": "CAD: Canadian Dollar"},
    { "value": "CDF", "key": "CDF: Congolese Franc"},
    { "value": "CHE", "key": "CHE: WIR Euro"},
    { "value": "CHF", "key": "CHF: Swiss Franc"},
    { "value": "CHW", "key": "CHW: WIR Franc"},
    { "value": "CLF", "key": "CLF: Unidades de fomento" },
    { "value": "CLP", "key": "CLP: Chilean Peso" },
    { "value": "CNY", "key": "CNY: Yuan Renminbi" },
    { "value": "COP", "key": "COP: Colombian Peso" },
    { "value": "COU", "key": "COU: Unidad de Valor Real" },
    { "value": "CRC", "key": "CRC: Costa Rican Colon" },
    { "value": "CUC", "key": "CUC: Peso Convertible" },
    { "value": "CUP", "key": "CUP: Cuban Peso" },
    { "value": "CVE", "key": "CVE: Cape Verde Escudo" },
    { "value": "CZK", "key": "CZK: Czech Koruna" },
    { "value": "DJF", "key": "DJF: Djibouti Franc" },
    { "value": "DKK", "key": "DKK: Danish Krone" },
    { "value": "DOP", "key": "DOP: Dominican Peso" },
    { "value": "DZD", "key": "DZD: Algerian Dinar" },
    { "value": "EGP", "key": "EGP: Egyptian Pound" },
    { "value": "ERN", "key": "ERN: Nakfa" },
    { "value": "ETB", "key": "ETB: Ethiopian Birr" },
    { "value": "EUR", "key": "EUR: Euro" },
    { "value": "FJD", "key": "FJD: Fiji Dollar" },
    { "value": "FKP", "key": "FKP: Falkland Islands Pound" },
    { "value": "GBP", "key": "GBP: Pound Sterling" },
    { "value": "GEL", "key": "GEL: Lari" },
    { "value": "GHS", "key": "GHS: Ghana Cedi" },
    { "value": "GIP", "key": "GIP: Gibraltar Pound" },
    { "value": "GMD", "key": "GMD: Dalasi" },
    { "value": "GNF", "key": "GNF: Guinea Franc" },
    { "value": "GTQ", "key": "GTQ: Quetzal" },
    { "value": "GYD", "key": "GYD: Guyana Dollar" },
    { "value": "HKD", "key": "HKD: Hong Kong Dollar" },
    { "value": "HNL", "key": "HNL: Lempira" },
    { "value": "HRK", "key": "HRK: Croatian Kuna" },
    { "value": "HTG", "key": "HTG: Gourde" },
    { "value": "HUF", "key": "HUF: Forint" },
    { "value": "IDR", "key": "IDR: Rupiah" },
    { "value": "ILS", "key": "ILS: New Israeli Sheqel" },
    { "value": "INR", "key": "INR: Indian Rupee" },
    { "value": "IQD", "key": "IQD: Iraqi Dinar" },
    { "value": "IRR", "key": "IRR: Iranian Rial" },
    { "value": "ISK", "key": "ISK: Iceland Krona" },
    { "value": "JMD", "key": "JMD: Jamaican Dollar" },
    { "value": "JOD", "key": "JOD: Jordanian Dinar" },
    { "value": "JPY", "key": "JPY: Yen" },
    { "value": "KES", "key": "KES: Kenyan Shilling" },
    { "value": "KGS", "key": "KGS: Som" },
    { "value": "KHR", "key": "KHR: Riel" },
    { "value": "KMF", "key": "KMF: Comoro Franc" },
    { "value": "KPW", "key": "KPW: North Korean Won" },
    { "value": "KRW", "key": "KRW: Won" },
    { "value": "KWD", "key": "KWD: Kuwaiti Dinar" },
    { "value": "KYD", "key": "KYD: Cayman Islands Dollar" },
    { "value": "KZT", "key": "KZT: Tenge" },
    { "value": "LAK", "key": "LAK: Kip" },
    { "value": "LBP", "key": "LBP: Lebanese Pound" },
    { "value": "LKR", "key": "LKR: Sri Lanka Rupee" },
    { "value": "LRD", "key": "LRD: Liberian Dollar" },
    { "value": "LSL", "key": "LSL: Loti" },
    { "value": "LTL", "key": "LTL: Lithuanian Litas" },
    { "value": "LVL", "key": "LVL: Latvian Lats" },
    { "value": "LYD", "key": "LYD: Libyan Dinar" },
    { "value": "MAD", "key": "MAD: Moroccan Dirham" },
    { "value": "MDL", "key": "MDL: Moldovan Leu" },
    { "value": "MGA", "key": "MGA: Malagasy Ariary" },
    { "value": "MKD", "key": "MKD: Denar" },
    { "value": "MMK", "key": "MMK: Kyat" },
    { "value": "MNT", "key": "MNT: Tugrik" },
    { "value": "MOP", "key": "MOP: Pataca" },
    { "value": "MRO", "key": "MRO: Ouguiya" },
    { "value": "MUR", "key": "MUR: Mauritius Rupee" },
    { "value": "MVR", "key": "MVR: Rufiyaa" },
    { "value": "MWK", "key": "MWK: Kwacha" },
    { "value": "MXN", "key": "MXN: Mexican Peso" },
    { "value": "MXV", "key": "MXV: Mexican Unidad de Inversion (UDI)" },
    { "value": "MYR", "key": "MYR: Malaysian Ringgit" },
    { "value": "MZN", "key": "MZN: Mozambique Metical" },
    { "value": "NAD", "key": "NAD: Namibia Dollar" },
    { "value": "NGN", "key": "NGN: Naira" },
    { "value": "NIO", "key": "NIO: Cordoba Oro" },
    { "value": "NOK", "key": "NOK: Norwegian Krone" },
    { "value": "NPR", "key": "NPR: Nepalese Rupee" },
    { "value": "NZD", "key": "NZD: New Zealand Dollar" },
    { "value": "OMR", "key": "OMR: Rial Omani" },
    { "value": "PAB", "key": "PAB: Balboa" },
    { "value": "PEN", "key": "PEN: Nuevo Sol" },
    { "value": "PGK", "key": "PGK: Kina" },
    { "value": "PHP", "key": "PHP: Philippine Peso" },
    { "value": "PKR", "key": "PKR: Pakistan Rupee" },
    { "value": "PLN", "key": "PLN: Zloty" },
    { "value": "PYG", "key": "PYG: Guarani" },
    { "value": "QAR", "key": "QAR: Qatari Rial" },
    { "value": "RON", "key": "RON: New Romanian Leu" },
    { "value": "RSD", "key": "RSD: Serbian Dinar" },
    { "value": "RUB", "key": "RUB: Russian Ruble" },
    { "value": "RWF", "key": "RWF: Rwanda Franc" },
    { "value": "SAR", "key": "SAR: Saudi Riyal" },
    { "value": "SBD", "key": "SBD: Solomon Islands Dollar" },
    { "value": "SCR", "key": "SCR: Seychelles Rupee" },
    { "value": "SDG", "key": "SDG: Sudanese Pound" },
    { "value": "SEK", "key": "SEK: Swedish Krona" },
    { "value": "SGD", "key": "SGD: Singapore Dollar" },
    { "value": "SHP", "key": "SHP: Saint Helena Pound" },
    { "value": "SLL", "key": "SLL: Leone" },
    { "value": "SOS", "key": "SOS: Somali Shilling" },
    { "value": "SRD", "key": "SRD: Surinam Dollar" },
    { "value": "SSP", "key": "SSP: South Sudanese Pound" },
    { "value": "STD", "key": "STD: Dobra" },
    { "value": "SVC", "key": "SVC: El Salvador Colon" },
    { "value": "SYP", "key": "SYP: Syrian Pound" },
    { "value": "SZL", "key": "SZL: Lilangeni" },
    { "value": "THB", "key": "THB: Baht" },
    { "value": "TJS", "key": "TJS: Somoni" },
    { "value": "TMT", "key": "TMT: Turkmenistan New Manat" },
    { "value": "TND", "key": "TND: Tunisian Dinar" },
    { "value": "TOP", "key": "TOP: Pa’anga" },
    { "value": "TRY", "key": "TRY: Turkish Lira" },
    { "value": "TTD", "key": "TTD: Trinidad and Tobago Dollar" },
    { "value": "TWD", "key": "TWD: New Taiwan Dollar" },
    { "value": "TZS", "key": "TZS: Tanzanian Shilling" },
    { "value": "UAH", "key": "UAH: Hryvnia" },
    { "value": "UGX", "key": "UGX: Uganda Shilling" },
    { "value": "USD", "key": "USD: US Dollar" },
    { "value": "USN", "key": "USN: US Dollar (Next day)" },
    { "value": "USS", "key": "USS: US Dollar (Same day)" },
    { "value": "UYI", "key": "UYI: Uruguay Peso en Unidades Indexadas (URUIURUI)" },
    { "value": "UYU", "key": "UYU: Peso Uruguayo" },
    { "value": "UZS", "key": "UZS: Uzbekistan Sum" },
    { "value": "VEF", "key": "VEF: Bolivar" },
    { "value": "VND", "key": "VND: Dong" },
    { "value": "VUV", "key": "VUV: Vatu" },
    { "value": "WST", "key": "WST: Tala" },
    { "value": "XAF", "key": "XAF: CFA Franc BEAC" },
    { "value": "XAG", "key": "XAG: Silver" },
    { "value": "XAU", "key": "XAU: Gold" },
    { "value": "XBA", "key": "XBA: Bond Markets Unit European Composite Unit (EURCO)" },
    { "value": "XBB", "key": "XBB: Bond Markets Unit European Monetary Unit (E.M.U.-6)" },
    { "value": "XBC", "key": "XBC: Bond Markets Unit European Unit of Account 9 (E.U.A.-9)" },
    { "value": "XBD", "key": "XBD: Bond Markets Unit European Unit of Account 17 (E.U.A.-17)" },
    { "value": "XCD", "key": "XCD: East Caribbean Dollar" },
    { "value": "XDR", "key": "XDR: SDR (Special Drawing Right)" },
    { "value": "XFU", "key": "XFU: UIC-Franc" },
    { "value": "XOF", "key": "XOF: CFA Franc BCEAO" },
    { "value": "XPD", "key": "XPD: Palladium" },
    { "value": "XPF", "key": "XPF: CFP Franc" },
    { "value": "XPT", "key": "XPT: Platinum" },
    { "value": "XSU", "key": "XSU: Sucre" },
    { "value": "XUA", "key": "XUA: ADB Unit of Account" },
    { "value": "XXX", "key": "XXX: The codes assigned for transactions where no currency is involved" },
    { "value": "YER", "key": "YER: Yemeni Rial" },
    { "value": "ZAR", "key": "ZAR: Rand" },
    { "value": "ZMW", "key": "ZMW: Zambian Kwacha" },
    { "value": "ZWL", "key": "ZWL: Zimbabwe Dollar" },
 ];
