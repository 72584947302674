import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { currencies } from "../../../datalists/currency";
import * as ModelConstants from "../../../models/model-constants";

@Component({
    selector: "skat-beloeb-felt",
    templateUrl: "./beloeb-felt.component.html"
})

export class BeloebFeltComponent implements OnInit {

    @Input() model: string;
    @Input() placeholderText: string;
    @Input() requiredText: string;
    @Input() negativeAllowed: boolean;
    @Input() label: string;
    @Output() modelChange = new EventEmitter<string>();

    get modelFelt() {
        return this.model;
    }

    set modelFelt(input: string) {
        this.model = input;
        this.modelChange.emit(this.model);
    }

    ngOnInit() {

    }

    currencies() {
        return currencies;
    }

    maxStringLength(): number {
        return ModelConstants.maxStringLength;
    }

    get beloebPattern() {
        return "/^-?([0-9]*)(\,[0-9]*)?$/!!/^-?(0|[^0].*)(\,[0-9]*)?$/!!/^[\-0-9a-zA-Z\.]*(\,[0-9a-zA-Z]{1,2}){0,1}$/"
            + ( this.negativeAllowed ? "" : "!!/^(?!-).*$/" );

    }

    get beloebFacetExpression() {
        return "pattern!!pattern" + (this.negativeAllowed ? "" : "!!pattern");

    }

}
