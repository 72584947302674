import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ModalModule } from "ngx-bootstrap";
import { BeloebFeltComponent } from "./components/partials/beloeb-felt/beloeb-felt.component";
import { DatovaelgerInputFeltComponent } from "./components/partials/datovaelger-input-felt/datovaelger-input-felt.component";
import { FejlListeComponent } from "./components/partials/fejl-liste/fejl-liste.component";
import { HjaelpetekstComponent } from "./components/partials/hjaelpetekst/hjaelpetekst.component";
import { JaNejInputFeltComponent } from "./components/partials/ja-nej-input-felt/ja-nej-input-felt.component";
import { OnLinkOnEnterDirective } from "./directives/focus-on-link-on-enter.directive";
import { TranslationPipe } from "./pipes/translation.pipe";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ModalModule.forRoot()
    ],
    declarations: [
        OnLinkOnEnterDirective,
        HjaelpetekstComponent,
        FejlListeComponent,
        DatovaelgerInputFeltComponent,
        TranslationPipe,
        BeloebFeltComponent,
        JaNejInputFeltComponent
    ],
    exports: [
        RouterModule,
        FormsModule,
        HttpClientModule,
        ModalModule,
        OnLinkOnEnterDirective,
        HjaelpetekstComponent,
        FejlListeComponent,
        DatovaelgerInputFeltComponent,
        TranslationPipe,
        BeloebFeltComponent,
        JaNejInputFeltComponent
    ],
    providers: []
 })
export class SharedModule {}
