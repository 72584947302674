import { Injectable } from "@angular/core";
import { Fejl } from "../../../shared/models/fejl.model";
import { isDefined } from "../../../shared/utils/validation-utils";
import { AccountReportModel } from '../../models/account-report.model';
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { IdNumberModel } from "../../models/id-number.model";
import { IdNumberValidator } from "../../models/id-number.validator";
import { PaymentModel } from '../../models/payment.model';
import { EnkeltindberetningValidator } from "./enkeltindberetning-validator.interface";
import { KontoEjerValidatorCrs } from "./kontoejer-validator-crs";
import { KontoEjerValidatorFatca } from "./kontoejer-validator-fatca";

@Injectable()
export class KontoEjerValidatorService implements EnkeltindberetningValidator {

    validate(model: EnkeltindberetningModel, erFatca: boolean): Fejl[] {
        const commonErrors: Fejl[] = this.validateCommon(model);
        if (erFatca) {
            return commonErrors.concat(KontoEjerValidatorFatca.validate(model));
        } else {
            return commonErrors.concat(KontoEjerValidatorCrs.validate(model));
        }
    }

    // Validations that apply both to CRS and FATCA
    validateCommon(model: EnkeltindberetningModel): Fejl[] {
        return this.validateAccountReport(model.accountReport)
            .concat(this.validatePayment(model.accountReport.payments))
            .concat(this.validateIdFields(model.idNumbers));
    }

    validateAccountReport(model: AccountReportModel): Fejl[] {
        const errors: Fejl[] = [];
        if (model.accountNumberType === "OECD601" && !/^[A-Z]{2}[0-9]{2}[0-9,A-Z]{10,30}$/.test(model.accountNumber)) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.OECD601_FORMAT"));
        }
        if (model.accountNumberType === "OECD603" && !/^[A-Z]{2}[0-9,A-Z]{9}[0-9]{1}$/.test(model.accountNumber)) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.OECD603_FORMAT"));
        }
        return errors;
    }

    validateIdFields(idNumbers: IdNumberModel[]): Fejl[] {
        return IdNumberValidator.validateIssuedBy(idNumbers);
    }

    validatePayment(payments: PaymentModel): Fejl[] {
        const errors: Fejl[] = [];
        if (isDefined(payments.dividendsAmount) && !isDefined(payments.dividendsCurrencyCode)) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.DIVIDENS_VALUTAKODE_PAKRAAVET"));
        }
        if (isDefined(payments.interestAmount) && !isDefined(payments.interestCurrencyCode)) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.INTEREST_VALUTAKODE_PAKRAAVET"));
        }
        if (isDefined(payments.redemptionAmount) && !isDefined(payments.redemptionCurrencyCode)) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.REDEMPTION_VALUTAKODE_PAKRAAVET"));
        }
        if (isDefined(payments.otherAmount) && !isDefined(payments.otherCurrencyCode)) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.OTHER_VALUTAKODE_PAKRAAVET"));
        }
        return errors;
    }
}
