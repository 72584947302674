import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import {
    genererFinansielleInstitutter,
    genererIndberetningsaarListeForHistorik
} from "../../../shared/utils/calculations-utils";
import { clearRecentlySubmittedEnkeltIndberetninger } from '../../../shared/utils/recent-indberetninger-helper';
import { waitForPligtigAndPerformAction } from "../../../shared/utils/session-storage-utils";
import { formatDate, isDefined } from "../../../shared/utils/text-utils";
import { KontoejerModel } from "../../models/kontoejer.model";
import { KontoejerPagedResultModel } from "../../models/kontoejerPagedResult.model";
import { OversigtFilterValuesModel } from "../../models/oversigt-filter-values.model";
import { HentHistorikService } from "../../services/hent-historik/hent-historik.service";
import { KontoejereService } from "../../services/hent-kontoejere/kontoejere.service";

@Component({
    templateUrl: './kontoejere-oversigt.component.html',
    providers: [KontoejereService, HentHistorikService],
    styleUrls: ['./kontoejere-oversigt.component.css', '../../../shared/css/filter-panel.css', '../../../shared/css/paginering.css']
})
export class KontoejereOversigtComponent extends IndberetningstypeAwareness implements OnInit, OnDestroy {

    errors: ErrorService;

    forespoergselUnderBehandling: boolean = false;

    accountReports: KontoejerModel[] = [];

    groupedAccountReports: Map<string, KontoejerModel[]> = new Map();
    selectedAccountReportsForDeletion:Map<string, boolean> = new Map();
    alreadyDeletedAccountReports: Set<string> = new Set();

    hasFocused: boolean;
    focusTimeoutMs: number = 100;
    focusTryCount: number = 10;
    reportsProcessingTimeoutMs: number = 3000;
    stopCheckingReportsProcessing: boolean = false;
    loadCount = 0;
    hentSpinnerText: string;
    filterSpinnerText: string;
    cpPostfix: string;

    collapse: boolean = true;

    selectedYear: string = "";
    selectedAccNumber: string = "";
    selectedAccHolderName: string = "";
    selectedAccId: string = "";

    filterCriterias: OversigtFilterValuesModel = new OversigtFilterValuesModel();
    muligeIndberetningsaar: string[];

    possibleFi: string[];
    selectedFi: string = "";
    searchedFi: string = "";
    fiValueForAlle: string = "Alle afsendere";
    fiKeyForAlle: string = "alle";

    searchParam: string = "";

    columnNameDato: string = 'dato';
    columnNameNavn: string = 'navn';
    columnNameKontonummer: string = 'kontonummer';
    columnNameKontoId: string = 'kontoId';

    sortColumn: string = this.columnNameDato; // Ensure there's always some initital sorting specified when retrieving from backend
    sortAscending: boolean = false;

    formatDate = formatDate;

    // Paging information
    actualPageSize: number = 20;
    pageSizeInput: number = 20;
    paginationOffset: number = 0;
    currentPageInput: number = 1;
    actualCurrentPage: number = 1;
    totalPages: number;
    totalCount: number; // the total number of results for current filtering parameters

    constructor(protected router:Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private hentService: KontoejereService,
                private translationService: TranslationService,
                private errorService: ErrorService,
                private hentHistorikService: HentHistorikService,
                private titleService: TitleService) {
        super();
        this.errors = errorService;
        this.errors.reset();
        this.muligeIndberetningsaar = genererIndberetningsaarListeForHistorik();
    }

    ngOnInit() {
        this.subscribeToServiceResponses();
        this.loadCount = 0;
        page.showLoader("Loader siden...");

        // Translations setup
        this.translationService.environmentAwareTranslate('KONTOEJEROVERSIGT.HENT_SPINNER').subscribe((res: string) => {
            this.hentSpinnerText = res;
            this.increaseLoadCounter();
        });
        this.translationService.environmentAwareTranslate('KONTOEJEROVERSIGT.FILTRER_SPINNER').subscribe((res: string) => {
            this.filterSpinnerText = res;
            this.increaseLoadCounter();
        });
        this.translationService.environmentAwareTranslate('GENERELT.SEARCH.FINANSIEL_INSTITUT.DEFAULT').subscribe((res: string) => {
            this.fiValueForAlle = res;
            this.increaseLoadCounter();
        });
        this.translationService.environmentAwareTranslate('GENERELT.KONTROLLERENDEPERSON.POSTFIX').subscribe((res: string) => {
            this.cpPostfix = res;
            this.increaseLoadCounter();
        });

        waitForPligtigAndPerformAction(() => {
            this.increaseLoadCounter();
        }, () => {
            this.increaseLoadCounter();
            page.hideLoader();
        });
        clearRecentlySubmittedEnkeltIndberetninger();
    }

    switchedPageSize() {
        // Check if input number is valid before proceeding
        if (this.invalidPageSizeEntered) {
            return;
        }
        // Great, user entered an acceptable number
        this.actualPageSize = this.pageSizeInput;

        // Reset to page 1
        this.goToPageAndUpdatePagingParams(1);
    }

    nextPage(): void {
        if(this.totalPages > this.actualCurrentPage) {
            this.goToPageAndUpdatePagingParams(this.actualCurrentPage + 1);
        }
    }

    previousPage(): void {
        if(this.actualCurrentPage > 1) {
            this.goToPageAndUpdatePagingParams(this.actualCurrentPage - 1);
        }
    }

    /**
     * Previous page button should be disabled when we're on the first page
     */
    get goToPreviousPageShouldBeDisabled():boolean {
        return this.actualCurrentPage === 1;
    }

    /**
     * Next page button should be disabled when we're on the last page
     */
    get goToNextPageShouldBeDisabled():boolean {
        return this.actualCurrentPage === this.totalPages;
    }

    /**
     * Returns true when the entered page size is invalid (null, zero or negative)
     * Entering text in inputfield such as 'e' will effectively assign null to pageSizeInput
     */
    get invalidPageSizeEntered(): boolean {
        if (this.pageSizeInput === null) {
            // This may happen if the user enters 'e' (Euler's number) (which input-field happens to accept)
            return true;
        }
        return this.pageSizeInput < 1;
    }

    /**
     * Returns true if currentPageInput is out of bounds or not a number
     */
    get pagePickerInputHasError(): boolean {
        return this.currentPageInput === null || this.currentPageInput > this.totalPages || this.currentPageInput < 1;
    }

    get getTotalPages(): number {
        return this.totalPages;
    }

    /**
     * Page picker should be disabled when there is only one page to show
     */
    get pagePickerShouldBeDisabled(): boolean {
        return this.getTotalPages === 1;
    }

    /**
     * Updates the pagination input and correlated variables
     */
    goToPage(): void {
        if(this.totalPages >= this.currentPageInput && this.currentPageInput > 0) {
            this.goToPageAndUpdatePagingParams(this.currentPageInput);
        }
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    ngOnDestroy() {
        this.stopCheckingReportsProcessing = true;
    }

    get visSupplerKnap(): boolean {
        return this.erCrs() && isDefined(this.possibleFi) && this.possibleFi.length > 0;
    }

    increaseLoadCounter() {
        this.loadCount = this.loadCount + 1;
        if (this.loadCount === 5) {
            this.onPageLoadFinished();
        }
    }

    onPageLoadFinished() {
        page.hideLoader();
        this.selectedYear = (new Date().getFullYear()-1).toString();
        this.selectedFi = this.fiKeyForAlle;
        this.filterChanged();
        this.hentHistorikService.getReportsProcessing();
    }

    private subscribeToServiceResponses() {

        this.hentHistorikService.reportsProcessingCompleted.subscribe(
            (reportsProcessing: boolean) => {
                page.hideLoader();

                if (reportsProcessing) {
                    this.forespoergselUnderBehandling = true;
                    this.waitAndGetReportsProcessing();
                } else {
                    if (this.forespoergselUnderBehandling) {
                        this.forespoergselUnderBehandling = false;
                        this.hentService.hentKontoejere(this.filterCriterias, this.paginationOffset, this.actualPageSize);
                    }
                }
            }
        );

        this.hentHistorikService.responseError.subscribe(
            (fejl: Fejl) => {
                if (fejl) {
                    page.hideLoader();
                    this.errors.tilfoejFejl([fejl]);
                }
            }
        );

        this.hentService.searchResult.subscribe(
            (pagedResultModel: KontoejerPagedResultModel) => {
                page.hideLoader();
                this.accountReports = pagedResultModel.accountReports.map((ar: KontoejerModel) => Object.assign(new KontoejerModel(), ar));
                this.possibleFi = genererFinansielleInstitutter(pagedResultModel.accountReports);

                // Update paging-information
                this.paginationOffset = pagedResultModel.offset;
                this.totalPages = pagedResultModel.totalPages;
                this.totalCount = pagedResultModel.totalCount;
                // Backend might give us another (typically lesser) pagesize than we asked for, so update accordingly
                this.actualPageSize = pagedResultModel.pageSize;

                this.updaterSelectedFinansielInstitut();
                if (!this.hasFocused) {
                    this.hasFocused = true;
                    if (pagedResultModel.accountReports.length > 0) {
                        this.focusOnSupplerButton(this.focusTryCount);
                    } else {
                        this.focusOnFilterBarExpansion();
                    }
                }
            }
        );

        this.hentService.errorResponses.subscribe(
            (fejl: Fejl) => {
                page.hideLoader();
                this.errors.tilfoejFejl([fejl]);
            }
        );
    }

    nameForFi(fi:string):string {
        if (fi && fi !== "" && fi !== "Alle afsendere" && this.accountReports) {
            const ar = this.accountReports.find((ar: KontoejerModel) => { return ar && ar.fiDocRefId === fi; });
            return ar ? ar.fiName : "";
        }
        return "";
    }

    waitAndGetReportsProcessing() {
        if (!this.stopCheckingReportsProcessing) {
            setTimeout(() => {
                this.hentHistorikService.getReportsProcessing();
            }, this.reportsProcessingTimeoutMs);
        }
    }

    /**
     * Focuses on the "suppler" button using a timeout. The timeout is necessary because the button doesn't show
     * immediately but a short while after the data has been fetched.
     */
    private focusOnSupplerButton(numberOfTriesLeft: number): void {
        if (numberOfTriesLeft < 1) {
            // Give up after too many tries
            return;
        }
        // Set a timeout after which to focus the element
        window.setTimeout(() => {
            const element = document.getElementById('suppler-knap');
            if (element) {
                element.focus();
            } else {
                this.focusOnSupplerButton(numberOfTriesLeft - 1);
            }
        }, this.focusTimeoutMs);
    }

    private focusOnFilterBarExpansion(): void {
        document.getElementById('filter-bar-expansion').focus();
    }

    updaterSelectedFinansielInstitut() {
        if (this.selectedFi && this.possibleFi.length === 1) {
            this.selectedFi = this.possibleFi[0];
        }
    }

    get fiDisabed():boolean {
        return this.possibleFi ? this.possibleFi.length <= 1: false;
    }

    get yearDisabed():boolean {
        return this.muligeIndberetningsaar ? this.muligeIndberetningsaar.length <= 1: false;
    }

    filterChanged(reset:boolean = false) {

        if (reset) {
            // Reset the filter criteria - if year has been changed f.ex.
            this.selectedAccId = '';
            this.selectedAccNumber = '';
            this.selectedAccHolderName = '';
        }

        this.filterCriterias.year = this.selectedYear;
        this.filterCriterias.accId = this.selectedAccId;
        this.filterCriterias.accNumber = this.selectedAccNumber;
        this.filterCriterias.accHolderName = this.selectedAccHolderName;

        this.selectedFi = this.fiKeyForAlle;

        // filter criteria has changed, jump back to page one.
        this.goToPageAndUpdatePagingParams(1);
    }

    sorterEfterDato() {
        const currentlySortedBy = this.sortColumn;
        this.sortColumn = this.columnNameDato;
        this.doSort(currentlySortedBy, false);
    }

    sorterEfterNavn() {
        const currentlySortedBy = this.sortColumn;
        this.sortColumn = this.columnNameNavn;
        this.doSort(currentlySortedBy, true);
    }

    sorterEfterKontonummer() {
        const currentlySortedBy = this.sortColumn;
        this.sortColumn = this.columnNameKontonummer;
        this.doSort(currentlySortedBy, true);
    }

    sorterEfterKontoId() {
        const currentlySortedBy = this.sortColumn;
        this.sortColumn = this.columnNameKontoId;
        this.doSort(currentlySortedBy, true);
    }

    private doSort(currentlySortedBy: string, defaultAscending: boolean) {
        if (this.sortColumn === currentlySortedBy) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortAscending = defaultAscending;
        }
        this.hentKontoejereSorteret();
    }

    private hentKontoejereSorteret() {
        this.errors.reset();
        this.hentService.hentKontoejere(this.filterCriterias, this.paginationOffset, this.actualPageSize, this.sortColumn, this.sortAscending);
    }

    selectOrDeselectAllAccountReports(selectAll:boolean) {
        this.accountReports.forEach(accountReport => {
            this.selectOrDeselectAccountReport(accountReport.arDocRefId, selectAll);
        });
    }

    selectOrDeselectAccountReport(arDocRefId:string, select:boolean) {
        this.selectedAccountReportsForDeletion.set(arDocRefId, select);
        this.updateGroupMap();
    }

    areAllElementsChecked() {
        return this.accountReports.length === this.getSelectCount();
    }

    getSelectCount() {
        return Array.from(this.selectedAccountReportsForDeletion.values()).filter(value => value).length;
    }

    getKeysForGroupedAccountReports() {
        return Array.from(this.groupedAccountReports.keys());
    }

    private updateGroupMap():void {
        this.groupedAccountReports.clear();
        this.accountReports.forEach(ar => {
            if(this.selectedAccountReportsForDeletion.get(ar.arDocRefId)) {
                if(this.groupedAccountReports.has(ar.fiDocRefIdAndNameGroup())) {
                    this.groupedAccountReports.get(ar.fiDocRefIdAndNameGroup()).push(ar);
                } else  {
                    this.groupedAccountReports.set(ar.fiDocRefIdAndNameGroup(), [ar]);
                }
            }
        });
    }

    deleteAccountReports() {
        const arDocRefIds:string[] = [];
        this.selectedAccountReportsForDeletion.forEach((value, key) => {
            if (value) {
                arDocRefIds.push(key);
                this.alreadyDeletedAccountReports.add(key);
            }
        });
        this.hentService.sletAccountReports(this.filterCriterias.year, arDocRefIds);

        // Clean up
        this.selectedAccountReportsForDeletion.clear();
        this.groupedAccountReports.clear();

        // Show advis and reload
        this.forespoergselUnderBehandling = true;
        this.waitAndGetReportsProcessing();
    }

    suppler() {
        if (isDefined(this.selectedYear)) {
            const params = {
                "year": this.selectedYear
            };
            if (["", "alle"].indexOf(this.selectedFi) < 0) {
                const fi = "fi";
                params[fi] = this.selectedFi;
            }
            this.router.navigate(["/enkeltindberetning", params]);
        }
    }

    getSortingClass(columnName: string) : string {
        if (columnName === this.sortColumn) {
            return this.sortAscending ? 'sortAscending' : 'sortDescending';
        }
        else {
            return null; // Not the currently selected column so no class available
        }
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('KONTOEJEROVERSIGT.OVERSKRIFT');
    }

    nameTooltip(report: KontoejerModel): string {
        let tooltip: string = report.accountHolderName;
        let cpName: string;
        for (cpName of report.cpNames()) {
            // See https://html.spec.whatwg.org/#the-title-attribute concerning the use of newlines
            tooltip = tooltip + `\n${cpName}${this.cpPostfix}`;
        }
        return tooltip;
    }

    private goToPageAndUpdatePagingParams(pageNumber: number) {
        this.paginationOffset = (pageNumber - 1) * this.actualPageSize;
        page.showLoader(this.hentSpinnerText);
        this.hentService.hentKontoejere(this.filterCriterias, this.paginationOffset, this.actualPageSize, this.sortColumn, this.sortAscending);
        this.actualCurrentPage = pageNumber;
        this.currentPageInput = this.actualCurrentPage;
    }
}
