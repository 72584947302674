
export function todayAsPrettyText(): string {
    const today = new Date();
    const aar:string = today.getFullYear().toString();
    const maaned:string = (today.getMonth() + 1).toString();
    const date:string = today.getDate().toString();

    return (date.length === 1 ? "0" + date : date) +
        "-" + (maaned.length === 1 ? "0" + maaned : maaned) +
        "-" + aar;
}

export function formatDate(dateTime: string): string {
    if (!isDefined(dateTime)) {
        return "";
    }

    const splitted = dateTime.split(" ");
    if (!isDefined(splitted)) {
        return dateTime;
    }

    const date = splitted[0];
    const time = splitted[1];

    return date + " kl. " + time;
}

export function isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
}

export function isDefinedAndNotEmpty(value: any): boolean {
    return isDefined(value) && value !== "" && value !== [];
}

export function textContainsSubtext(text:string, subtext:string):boolean {
    return isDefinedAndNotEmpty(text) ? text.indexOf(subtext) !== -1: false;
}

export function splitTextAndGetLast(text:string, splitter:string):string {
    const texts = textContainsSubtext(text, splitter) ? text.split(splitter) : [text];
    return texts[texts.length-1];
}

export function removeCRSdocRefPrefix(id: string): string {
    const regex = /^[A-z]{2}[0-9]{4}[0-9]{8}[FI]{2}/g;
    if (id.length > 16 && regex.test(id)) {
        return id.substr(16);
    }
    return id;
}

//E.g. ABCABC.DEDEF.AB.234.12345678.etId => etId
export function removeFATCAdocRefPrefix(id: string): string {
    const regex = /^[A-NP-Z0-9]{6}[.][A-NP-Z0-Z]{5}[.][A-NP-Z]{2}[.]\d{3}[.]\d{8}[.]/g;
    if (id.length > 29 && regex.test(id)) {
        return id.substr(29);
    }
    return id;
}

export function splitCrsDocRefId(id: string): any {
    let prefix = id;
    let value = "";
    if (isDefinedAndNotEmpty(id) && id.length > 16) {
        value = id.substr(16);
        prefix = id.substr(0,16);
    }
    return {
        'prefix': prefix,
        'value': value
    };
}
