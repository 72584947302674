import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CrsFiDocRefPipe } from "../shared/pipes/crs-fi-doc-ref.pipe";
import { FatcaFiDocRefPipe } from "../shared/pipes/fatca-fi-doc-ref.pipe";
import { SharedModule } from "../shared/shared.module";
import { HistorikComponent } from "./components/historik/historik.component";
import { KontoejereOversigtComponent } from "./components/kontoejere-oversigt/kontoejere-oversigt.component";
import { tidligereIndberetningerRouting } from "./tidligere-indberetninger.routing";

@NgModule({
    imports: [
        CommonModule,
        tidligereIndberetningerRouting,
        SharedModule
    ],
    declarations: [
        HistorikComponent,
        KontoejereOversigtComponent,
        CrsFiDocRefPipe,
        FatcaFiDocRefPipe
    ],
    exports: [
        HistorikComponent,
        KontoejereOversigtComponent,
        CrsFiDocRefPipe,
        FatcaFiDocRefPipe
    ]
 })
export class TidligereIndberetningerModule {
}
