import { OverviewItemModel } from "./overview-item.model";

export class HistoryRecordModel extends OverviewItemModel {
    id: number;
    messageRefId: string;
    reportingYear: number;
    status: string;
    statusLink: string;
    reportingLink: string;

    constructor(id: number,
                messageRefId: string,
                fiDocRefId: string,
                fiName: string,
                date: string,
                reportingYear: number,
                status: string,
                statusLink: string,
                reportingLink: string) {
        super();
        this.id = id;
        this.messageRefId = messageRefId;
        this.fiDocRefId = fiDocRefId;
        this.fiName = fiName;
        this.date = date;
        this.reportingYear = reportingYear;
        this.status = status;
        this.statusLink = statusLink;
        this.reportingLink = reportingLink;
    }
}
