import { HttpClient, HttpXhrBackend }  from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class SkatHttp extends HttpClient {
    constructor(backend: HttpXhrBackend) {
        super(backend);
    }

    post(url: string, body: any, options?: any): Observable<any> {
        const newUrl: string = encodeURI(url);
        return super.post(newUrl, body, options);
    }

    get(url: string, options?: any): Observable<any> {
        const newUrl: string = encodeURI(url);
        return super.get(newUrl, options).map(res => res);
    }
}

/* TODO: still usefull? or replaced by HttpXhrBackend
class MyBaseRequestOptions extends BaseRequestOptions {
    headers: Headers = new Headers({
        'X-Requested-With': 'XMLHttpRequest'
    });
    withCredentials: boolean = true;
}
*/
