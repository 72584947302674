import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export const DK_LANGUAGE_CODE = "DA";

const languageCodes = [DK_LANGUAGE_CODE];

@Injectable()
export class LanguageFileService {

    private languageFiles: Map<string, Observable<Object>>;

    constructor(private http: HttpClient) {
        this.languageFiles = new Map<string, Observable<Object>>();
        languageCodes.forEach(languageCode => {
            const fileName = `./assets/text-data-${languageCode.toLowerCase()}.json`;
            const languageFile = this.http.get(fileName).shareReplay(1);
            this.languageFiles.set(languageCode, languageFile);
        });
    }

    getLanguageFile(languageCode: string): Observable<Object> {
        return this.languageFiles.get(languageCode);
    }
}
