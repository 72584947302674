import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { schemaFejlOverskrift, standardOverskrift } from "../../../shared/utils/fejl-utils";
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadService } from "../../services/filupload/filupload.service";

@Component({
    templateUrl: './filoverfoersel-indberet.component.html',
    providers: [FiluploadService],
    styleUrls: ['./filoverfoersel-indberet.component.css']
})

export class FiloverfoerselIndberetComponent extends IndberetningstypeAwareness implements OnInit {

    model:FiloverfoerselModel;
    errors:ErrorService;
    errorsOverskrift:string;
    spinnerTekst:string = "";

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private filoverfoerselModel:FiloverfoerselModel,
                private service:FiluploadService,
                private errorService:ErrorService,
                private translationService: TranslationService,
                private titleService: TitleService) {
        super();
        this.model = filoverfoerselModel;
        this.errors = errorService;
        this.errorsOverskrift = standardOverskrift();
    }

    ngOnInit() {
        this.errors.reset();
        this.subscribePaaSuccess();
        this.subscribePaaFejl();
        this.translationService.environmentAwareTranslate('FILOVERFOERSEL.INDBERET.SPINNER').subscribe((res: string) => {
            this.spinnerTekst = res;
        });
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }
    indberet() {
        this.errors.reset();
        page.showLoader(this.spinnerTekst);
        this.service.uploadFil();
    }

    onFilValgt(event: any) {
        this.model.fil = event.target.files[0];
    }

    onFilFjernet() {
        this.model.fil = null;
    }

    subscribePaaSuccess() {
        this.service.uploadCompleted.subscribe(
            () => {
                page.hideLoader();
                this.router.navigate(['/filupload/modtaget']);
            }
        );
    }

    subscribePaaFejl() {
        this.service.uploadCompletedWithError.subscribe(
            (fejl:Fejl[]) => {
                page.hideLoader();
                this.filoverfoerselModel.fil = null;
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
                if (this.errors.indeholderFejlMedKode("121")) {
                    this.errorsOverskrift = schemaFejlOverskrift();
                } else {
                    this.errorsOverskrift = standardOverskrift();
                }
            }
        );
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('FILOVERFOERSEL.INDBERET.OVERSKRIFT');
    }
}
