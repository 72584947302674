import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterCategories } from "../../../shared/datalists/filter-category";
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import * as ModelConstants from "../../../shared/models/model-constants";
import { ErrorService } from "../../../shared/services/error/error.service";
import { FormSubmissionService } from "../../../shared/services/form-submission/form-submission.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { genererIndberetningsaarListe } from "../../../shared/utils/calculations-utils";
import {
    clearEnkeltindberetningFields,
    waitForPligtigAndPerformAction
} from "../../../shared/utils/session-storage-utils";
import { isDefined } from "../../../shared/utils/text-utils";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { EnkeltindberetningFlowService } from "../../services/enkeltindberetning-flow/enkeltindberetning-flow.service";
import { HentFiListeService } from "../../services/hent-fi-liste/hent-fi-liste.service";
import { EnkeltIndberetningValidatorService } from "../../services/validation/enkeltindberetning-validator.service";

@Component({
    templateUrl: './enkeltindberetning-indberetning.component.html',
    providers: [FormSubmissionService, EnkeltIndberetningValidatorService, HentFiListeService]
})

export class EnkeltindberetningIndberetningComponent extends IndberetningstypeAwareness implements AfterViewInit  {

    muligeIndberetningsaar = genererIndberetningsaarListe();
    private successUrl = "/enkeltindberetning/kontoejer";
    flow: EnkeltindberetningFlowService;
    model: EnkeltindberetningModel;

    preselectedFI:string = null;
    preselectedYear:string = null;

    @ViewChild('initialFocus') initialFocusElement: ElementRef;

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private activatedRoute: ActivatedRoute,
                private formSubmissionService: FormSubmissionService,
                public errors: ErrorService,
                private validator: EnkeltIndberetningValidatorService,
                private hentFiListeService: HentFiListeService,
                private enkeltindberetningFlowService: EnkeltindberetningFlowService,
                private titleService: TitleService) {
        super();
        this.flow = enkeltindberetningFlowService;

        this.preselectedFI = this.activatedRoute.snapshot.params.fi;
        this.preselectedYear = this.activatedRoute.snapshot.params.year;
        if (!this.activatedRoute.snapshot.params.persist) {
            clearEnkeltindberetningFields();
            this.flow.init();
        }
        this.updateModel();
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    private updateModel() {
        this.model = this.flow.getCurrentModel();
        this.model.address.countryCode = 'DK';
        this.generateRandomIds();
    }

    private generateRandomIds() {
        if (!this.flow.messageRefIdValue) {
            this.flow.messageRefIdValue = EnkeltindberetningFlowService.constructUUID();
        }
        if (!this.model.docRefIdValue) {
            this.model.docRefIdValue = EnkeltindberetningFlowService.constructUUID();
        }
    }

    ngOnInit() {
        this.formSubmissionService.init();
        this.errors.reset();
        this.subscribePaaSuccess();
        this.subscribePaaFejl();
        this.subscribePaaListOfFI();

        if (isDefined(this.preselectedYear)) {
            this.reportingYear = this.preselectedYear;
        }
    }

    ngAfterViewInit() {
        this.initialFocusElement.nativeElement.focus();
    }

    submit() {
        this.flow.saveInitielModel(this.model);
        this.errors.reset();
        this.formSubmissionService.validateAndNavigate(this.validator, this.model, this.erFatca());
    }

    get reportingYear(): string {
        return this.flow.reportingYear;
    }

    set reportingYear(value: string) {
        this.flow.reportingYear = value;

        //There is a risk that the pligtigSeNummer has not yet been loaded. So we wait for this.
        page.showLoader("Loader siden...");
        waitForPligtigAndPerformAction(() => {
            this.updateDocRefIdPrefix();
            this.hentFiListeService.hentFI(value);
        }, () => { });
    }

    get giin(): string {
        return this.model.giin;
    }

    set giin(value: string) {
        this.model.giin = value;
        this.updateDocRefIdPrefix();
    }

    hasControllingPersons(): boolean {
        return this.model.hasControllingPersons();
    }

    updateDocRefIdPrefix() {
        this.model.docRefIdPrefix = this.flow.constructDocRefIdPrefix(this.erFatca(), "FI", this.model.giin);
    }

    ngOnDestroy() {
        this.flow.saveInitielModel(this.model);
    }

    subscribePaaSuccess() {
        this.formSubmissionService.completed.subscribe(
            (svar:any) => {
                page.hideLoader();
                this.router.navigate([this.successUrl]);
            }
        );
    }

    subscribePaaFejl() {
        this.formSubmissionService.completedWithErrors.subscribe(
            (fejl:Fejl[]) => {
                page.hideLoader();
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
            }
        );

        this.hentFiListeService.fetchCompletedWithErrors.subscribe(
            (fejl:Fejl[]) => {
                page.hideLoader();
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
            }
        );
    }

    subscribePaaListOfFI() {
        this.hentFiListeService.fetchCompleted.subscribe((res: Map<string, EnkeltindberetningModel>) => {
            page.hideLoader();
            this.flow.mapOfFI = res;
            this.flow.listOfFI = Array.from(res.keys());

            if (this.flow.listOfFI.length > 0) {

                if (isDefined(this.preselectedYear)) {
                    this.flow.supplerende = true;
                }
                if (isDefined(this.preselectedFI) && this.flow.listOfFI.indexOf(this.preselectedFI) !== -1) {
                    this.flow.selectedFi = this.preselectedFI;
                    this.fiBlevValgt();
                }
            }
        });
    }

    filterCategories() {
        return filterCategories;
    }

    messageRefIdValueLength(): number {
        return this.erFatca() ? ModelConstants.fatcaMessageRefIdValueLength : ModelConstants.crsMessageRefIdValueLength;
    }

    docRefIdValueLength(): number {
        return this.erFatca() ? ModelConstants.fatcaDocRefIdValueLength : ModelConstants.crsDocRefIdValueLength;
    }

    maxStringLength(): number {
        return ModelConstants.maxStringLength;
    }

    fiBlevValgt() {
        this.flow.saveInitielModel(this.model);
        this.model = this.flow.getCurrentModel();
    }

    get inputsDisabled():boolean {
        return this.flow.supplerende;
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('ENKELTINDBERETNING.INDBERETNING.OVERSKRIFT');
    }
}
