import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Fejl } from "../../models/fejl.model";
import { AccessCheckService } from "../../services/access-check/access-check.service";
import { ErrorService } from "../../services/error/error.service";
import { WriteAccessCheckSubscriberService } from "../../services/write-access-check-subscriber/write-access-check-subscriber.service";

@Component({
    templateUrl: './fejlside.component.html'
})

export class FejlsideComponent {

    errors: ErrorService;

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                public accessCheckService: AccessCheckService,
                private route: ActivatedRoute,
                private errorService: ErrorService) {
        this.errors = this.errorService;
    }

    ngOnInit() {
        const besked = this.route.snapshot.params.besked;
        this.errors.reset();
        this.errors.tilfoejFejl([new Fejl(besked)]);
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }
}
