import { Component, Input } from '@angular/core';
import { DatovaelgerInputFeltComponent } from "../../../../shared/components/partials/datovaelger-input-felt/datovaelger-input-felt.component";
import { IndberetningstypeAwareness } from '../../../../shared/helpers/Indberetningstype-awareness';
import { maxStringLength } from "../../../../shared/models/model-constants";
import { CountryService } from "../../../../shared/services/country/country.service";
import { AddressModel } from "../../../models/address.model";
import { IdNumberModel } from "../../../models/id-number.model";
import { IndividualAccountHolderModel } from "../../../models/individual-account-holder.model";

/*
Genbrugelig component svarende til en Individual Account Holder:
    - Bopælsland(e)
    - Fødselsdag
    - TIN(s)
    - Navn
    - Adresse(r)
*/
@Component({
    selector: 'skat-person',
    templateUrl: './person.component.html'
})
export class PersonComponent extends IndberetningstypeAwareness {

    @Input() model: IndividualAccountHolderModel;
    @Input() displayResCountrySection: boolean = true;
    @Input() displayAddressSection: boolean = true;
    @Input() personIndex: number = 0;

    constructor(private countryService: CountryService) {
        super();
    }

    addAdress() {
        this.model.addresses.push(new AddressModel());
    }

    addBopaelsland() {
        this.model.resCountryCodes.push("");
    }

    removeBopaelsland(index: number) {
        this.model.resCountryCodes.splice(index, 1);
    }

    addId() {
        const idNumberModel = new IdNumberModel();
        if (this.erFatca()) {
            idNumberModel.issuedBy = 'US';
        }
        this.model.idNumbers.push(idNumberModel);
    }

    removeId(index: number) {
        this.model.idNumbers.splice(index, 1);
    }

    countries() {
        return this.countryService.getCountries();
    }

    currentYearString() {
        return new Date().getFullYear().toString();
    }

    crsCountries() {
        return this.countryService.crsCountries();
    }

    fatcaCountries() {
        return this.countryService.fatcaCountries();
    }

    maxStringLength(): number {
        return maxStringLength;
    }

    getDatovaelgerId(feltId: string, index: number): string {
        return DatovaelgerInputFeltComponent.getFeltNavn(feltId, index);
    }
}
