import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { NulindberetningIndberetComponent } from "./components/indberet/nulindberetning-indberet.component";
import { NulindberetningModtagetComponent } from "./components/modtaget/nulindberetning-modtaget.component";
import { NulindberetningModel } from "./models/nulindberetning.model";
import { nulindberetningRouting } from "./nulindberetning.routing";

@NgModule({
    imports: [
        CommonModule,
        nulindberetningRouting,
        SharedModule
    ],
    declarations: [
        NulindberetningIndberetComponent,
        NulindberetningModtagetComponent,
    ],
    exports: [
        NulindberetningIndberetComponent,
        NulindberetningModtagetComponent
    ],
    providers: [
        NulindberetningModel,
    ]
 })
export class NulindberetningModule {
}
