export class AddressModel {
    countryCode: string = "";
    street: string;
    buildingIdentifier: string;
    suiteIdentifier: string;
    floorIdentifier: string;
    districtName: string;
    pob: string;
    postCode: string;
    city: string;
    countrySubentity: string;
}
