import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { backendUrl } from "../../../shared/utils/url-utils";

@Injectable()
export class HentFilService {

    fejlVedHentningAfFil: Subject<any> = new Subject<any>();

    messageRef: string = '';
    fileName: string = '';

    constructor(private http: HttpClient) {
    }

    hentFil(path: string, messageRefId: string): void {
        this.messageRef = messageRefId;
        this.fileName = this.messageRef + '.xml';
        const url = backendUrl() + path;

        this.sendDownloadRequest(url).subscribe(
            (event: HttpEvent<any>) => {
                if (event.type === HttpEventType.Response) {
                    this.downloadBlob(event.body);
                }
            }, (error: any) => {
                this.fejlVedHentningAfFil.next(error);
            }
        );
    }

    /**
     * Creates and sends a GET request to the given URL, expecting a Blob response that can be downloaded.
     * @param url the URL to send the request to.
     * @return a stream of `HttpEvents`.
     */
    sendDownloadRequest(url: string): Observable<HttpEvent<any>> {
        const httpHeaderAcceptXML = new HttpHeaders().set('Content-Type', 'application/xml').set('Accept', 'application/xml');
        const httpRequest = new HttpRequest('GET', url, {
            headers: httpHeaderAcceptXML,
            responseType: 'blob',
            withCredentials: true,
        });
        return this.http.request(httpRequest);
    }

    /**
     * Downloads a Blob in a Browser-aware way.
     * @param blob the Blob to download.
     */
    downloadBlob(blob: Blob): void {
        // Allow download in IE10+: has Blob, but not a[download] or URL
        if (navigator.msSaveBlob) {
            this.downloadFileIe(blob);
        } else {
            this.downloadFileNonIe(blob);
        }
    }

    /**
     * Download file the IE way. IE doesn't support the download attribute on `a` elements, so we need another method.
     * @param blob a Blob containing the file to download.
     */
    downloadFileIe(blob: Blob) {
        navigator.msSaveBlob(blob, this.fileName);
    }

    /**
     * Download file in browers that are not IE.
     * @param blob a Blob containing the file to download.
     */
    downloadFileNonIe(blob: Blob) {
        const link = document.createElement('a');
        link.setAttribute("visibility", "hidden");
        link.download = this.fileName; // not supported in IE
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
    }
}
