import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Fejl } from "../../models/fejl.model";
import { AccessCheckService } from "../access-check/access-check.service";

@Injectable()
export class WriteAccessCheckSubscriberService {

    protected router: Router;
    public writeAccessCheckSubscription: Subscription; // exists for testing purposes
    harSkriverettigheder: boolean = true;

    constructor(private accessCheckService: AccessCheckService) {
    }

    subscribeToWriteAccessCheck() {
        this.writeAccessCheckSubscription = this.accessCheckService.writeAccessCheck.subscribe(
            (hasWriteAccess: boolean) => {
                this.harSkriverettigheder = hasWriteAccess;
            }
        );
        this.accessCheckService.writeAccessCheckFejl.subscribe(
            (fejl: Fejl) => {
                const besked = fejl.text();
                page.hideLoader();
                this.router.navigate(["/fejlside", {
                    besked: besked
                }]);
            }
        );
    }

    hasWriteAccess(): boolean {
        return this.harSkriverettigheder;
    }
}
