import { HistoryRecordModel } from "../../tidligere-indberetninger/models/history-record.model";
import { HentFilService } from "../../tidligere-indberetninger/services/hent-fil/hent-fil.service";
import { getStatusColorClass } from "../utils/css-utils";
import { isDefined } from '../utils/text-utils';
import { IndberetningstypeAwareness } from "./Indberetningstype-awareness";

export class MessageStatusHelper extends IndberetningstypeAwareness{

    constructor(protected hentFilService: HentFilService) {
        super();
    }

    reportings: HistoryRecordModel[];
    reportingToShow: HistoryRecordModel = null;

    reportingsHaveUnfinishedBusiness(reportings: HistoryRecordModel[]): boolean {
        return isDefined(reportings) ? reportings.some(r => r.status.toLowerCase() === "modtaget") : false;
    }

    getReportingToShow(oprettelsesid:number):HistoryRecordModel {
        return this.reportings && this.reportings.length > 0 ?
            this.reportings.find((rep:HistoryRecordModel) => rep.id === oprettelsesid) :
            null;
    }

    hentFil() {
        if (isDefined(this.reportingToShow)) {
            this.hentFilService.hentFil(this.reportingToShow.statusLink, this.reportingToShow.messageRefId);
        }
    }

    hasRejectedReporting() {
        return isDefined(this.reportingToShow) && this.reportingToShow.status === "Afvist";
    }

    getStatusColor() {
        return isDefined(this.reportingToShow) ? getStatusColorClass(this.reportingToShow.status) : {};
    }
}
