import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Fejl } from "../../models/fejl.model";
import { AccessCheckService } from "../../services/access-check/access-check.service";
import { ConfigService } from "../../services/config/config.service";
import { TranslationService } from "../../services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../services/write-access-check-subscriber/write-access-check-subscriber.service";
import {
    clearEnkeltindberetningFields,
    clearPligtigOgIndberetter,
    getIndberetningstype,
    getIndberetter,
    getPligtig,
    indberetterAndPligtigAreTheSame,
    setIndberetningstype,
    setIndberetter,
    setPligtig,
    setTextKeysVisible,
    setUserIsBeingValidated
} from "../../utils/session-storage-utils";
import { getUrlParam, goToPortal, pathsWhereDataCanBeLost } from "../../utils/url-utils";

@Component({
    selector: "skat-app",
    templateUrl: "./router.component.html"
})

export class RouterComponent implements OnInit, OnDestroy {
    currentUrl: string;

    subscriptionToConfigSuccess: Subscription;
    subscriptionToConfigFejl: Subscription;
    subscriptionToAccessChecks: Subscription;
    subscriptionToAccessCheckFejls: Subscription;

    confirmText: string;

    constructor(private configService: ConfigService,
                private accessCheckService: AccessCheckService,
                private router: Router,
                private translationService: TranslationService,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService) {
        this.subscribeToAccessChecks();
        this.subscribeToConfigChanges();
        this.subscribeToWriteAccessCheck();
    }

    ngOnInit() {
        this.translationService.environmentAwareTranslate('GENERELT.ROUTING.TIL_MENU_CONFIRM').subscribe((res: string) => {
            this.confirmText = res;
        });
        this.detectNyConfig();
    }

    ngOnDestroy() {
        this.subscriptionToConfigSuccess.unsubscribe();
        this.subscriptionToConfigFejl.unsubscribe();
        this.subscriptionToAccessChecks.unsubscribe();
        this.subscriptionToAccessCheckFejls.unsubscribe();
    }

    subscribeToConfigChanges() {
        this.subscriptionToConfigSuccess = this.configService.loginInfo.subscribe(
            (json: any) => {
                const eksisterendePligtig = getPligtig();
                const nyPligtig = json.pligtigSeNummer;
                const eksisterendeIndberetter = getIndberetter();
                const nyIndberetter = json.indberetterSeNummer;

                if (eksisterendePligtig !== nyPligtig || eksisterendeIndberetter !== nyIndberetter) {
                    setUserIsBeingValidated(true);
                    setPligtig(nyPligtig);
                    setIndberetter(nyIndberetter);

                    const loginContextHasChanged = eksisterendePligtig != null && eksisterendeIndberetter != null;
                    this.accessCheckService.checkAccess(loginContextHasChanged);
                }
            }
        );
        this.subscriptionToConfigFejl = this.configService.loginInfoFejl.subscribe(
            (fejl: any) => {
                const besked = fejl.text();
                if (getPligtig() == null && this.currentUrl !== "/fejlside") {
                    this.router.navigate(["/fejlside", {
                        besked: besked
                    }]);
                }
            }
        );
    }

    subscribeToWriteAccessCheck() {
        this.writeAccessCheckSubscriberService.subscribeToWriteAccessCheck();
    }

    subscribeToAccessChecks() {
        this.subscriptionToAccessChecks = this.accessCheckService.accessCheck.subscribe(
            (loginContextHasChanged: Boolean) => {
                setUserIsBeingValidated(false);
                if (loginContextHasChanged) {
                    window.alert(this.confirmText);
                    this.router.navigate(['/forside']);
                }
            }
        );
        this.subscriptionToAccessCheckFejls = this.accessCheckService.accessCheckFejl.subscribe(
            (fejl: Fejl) => {
                clearPligtigOgIndberetter(); // User should not be saved afterall, because he/she is not authorized
                setUserIsBeingValidated(false);
                const besked = fejl.text();
                page.hideLoader();
                this.router.navigate(["/fejlside", {
                    besked: besked
                }]);
            }
        );
    }

    detectNyConfig() {
        // Henter config og sammenligner hver gang man skifter side.
        this.router.events.filter(event => event instanceof NavigationStart)
            .subscribe(
                (event: NavigationStart) => {
                    if (event.url.indexOf("/fejlside") === -1) {
                        this.currentUrl = event.url;
                        if (this.fetchIndberetningstype()) {
                            this.configService.hentConfig();
                        } else {
                            this.router.navigate(["/fejlside", {besked: "Indberetningstype mangler"}]);
                        }
                    }
                }
            );
    }

    fetchIndberetningstype(): boolean {
        const tekster = this.teksterSkalVises();
        if (["true", "false"].indexOf(tekster) >= 0) {
            setTextKeysVisible(tekster);
        }

        const type = this.getNyInberetningstype();
        if (["crs", "fatca"].indexOf(type) >= 0) {
            if (type !== getIndberetningstype()) {
                clearEnkeltindberetningFields();
                clearPligtigOgIndberetter(); // Pligtig has to be validated again. It might not have access to other Indberetningstyper
            }
            setIndberetningstype(type);
        } else if (this.indberetningstypeErIkkeSat()) {
            return false;
        }
        return true;
    }

    getNyInberetningstype(): string {
        return getUrlParam("indberetningstype");
    }

    teksterSkalVises(): string {
        return getUrlParam("tekster");
    }

    indberetningstypeErIkkeSat(): boolean {
        return (getIndberetningstype() === null);
    }

    get indberetningstype(): string {
        const type = getIndberetningstype();
        return type ? type.toUpperCase() : "";
    }

    get pligtig(): string {
        return getPligtig();
    }

    get indberetter(): string {
        return getIndberetter();
    }

    get indberetterAndPligtigAreTheSame(): boolean {
        return indberetterAndPligtigAreTheSame();
    }

    get userMightLooseData(): boolean {
        const url = window.location.href;
        if (url.indexOf("/#/") !== -1) {
            const localPath = url.split("/#/")[1];
            if (pathsWhereDataCanBeLost.indexOf(localPath) >= 0) {
                return true;
            }
        }
        return false;
    }

    goToPortal() {
        let confirmed = true;
        if (this.userMightLooseData) {
            confirmed = window.confirm(this.confirmText);
        }
        if (confirmed) {
            goToPortal(this.router);
        }
    }
}
