import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CountryModel } from "../../models/country-model";
import { mapCountries } from "../../utils/mapping-utils";
import { landeUrl } from "../../utils/url-utils";

export const usCountryCode = 'US';
const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class CountryService {

    private countries: CountryModel[];

    constructor(private http: HttpClient) {
    }

    /**
     * Sets up a Promise which will be used as an indicator by APP_INITIALIZER
     * for when countries have been loaded (or failed to) from the backend.
     */
    fetchCountries(): Promise<any> {
        return this.http.get<any>(landeUrl(), httpOptions)
            .toPromise()
            .then((data: Object) => this.saveCountryModels(data));
    }

    /**
     * Maps and saves the countries in the response, sorted by name.
     * @param data the JSON data from the response
     */
    saveCountryModels(data: Object) {
        this.countries = mapCountries(data).sort((a: any, b: any) => a.name.localeCompare(b.name));
    }

    getCountries(): CountryModel[] {
        return this.countries;
    }

    /**
     * Countries with a CRS agreement.
     */
    crsCountries(): CountryModel[] {
        return this.countries.filter(country => country.hasAgreement && country.countryCode !== usCountryCode);
    }

    /**
     * Just US.
     */
    fatcaCountries(): CountryModel[] {
        return this.countries.filter(country => country.countryCode === usCountryCode);
    }
}

/**
 * This is a factory function for APP_INITIALIZER setup in the App module setup.
 * Its purpose is to load all language files before initializing components, etc.
 * See http://www.learn-angular.fr/how-to-call-an-asynchronous-service-before-bootstrap/
 */
export function countryServiceFactory(service: CountryService) {
    return () => service.fetchCountries();
}
