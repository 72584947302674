import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Subject } from "rxjs";
import { EnkeltindberetningValidator } from "../../../enkeltindberetning/services/validation/enkeltindberetning-validator.interface";
import { Fejl } from "../../models/fejl.model";
import { generateErrorItems } from "../../utils/fejl-utils";
import { TranslationService } from "../translate/translation.service";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class FormSubmissionService {

    completed: Subject<any> = new Subject<any>();
    completedWithErrors: Subject<any> = new Subject<any>();

    spinnerTekst: string = "";

    backendUrlFunc: () => string;

    constructor(private http: HttpClient,
                private translationService: TranslationService,
                private router: Router) {
    }

    public init() {
        this.translationService.environmentAwareTranslate('ENKELTINDBERETNING.INDBERET.SPINNER').subscribe((res: string) => {
            this.spinnerTekst = res;
        });
    }

    public validateAndNavigate(validator: EnkeltindberetningValidator, model: any, erFatca: boolean) {
        this.submit(validator, model, erFatca, false);
    }

    public validateAndPost(validator: EnkeltindberetningValidator, model: any, erFatca: boolean) {
        this.submit(validator, model, erFatca, true);
    }

    private submit(validator: EnkeltindberetningValidator, model: any, erFatca: boolean, shouldPost: boolean) {
        const errors: Fejl[] = validator.validate(model, erFatca);
        if (errors.length === 0) {
            if (shouldPost) {
                page.showLoader(this.spinnerTekst);
                this.postToBackend(model);
            } else {
                this.completed.next();
            }
        } else {
            this.completedWithErrors.next(errors);
        }
    }

    private postToBackend(model: any) {
        const payload = JSON.stringify(model);
        this.http.post(this.backendUrlFunc(), payload, httpOptions)
            .subscribe((json: any) => {
                this.completed.next(json);
            }, (error: any) => {
                const errors = generateErrorItems(error._body, error.status);
                this.completedWithErrors.next(errors);
            });
    }
}
