import { EnkeltindberetningModel } from '../../enkeltindberetning/models/enkeltindberetning.model';
import { RecentIndberetningModel } from '../models/recent-indberetning-model';
import { clearRecentSubmissions, getRecentSubmissions, setRecentSubmissions } from './session-storage-utils';

export function getRecentlySubmittedEnkeltIndberetninger(): RecentIndberetningModel[] {
    const raw = getRecentSubmissions();
    const parsed = JSON.parse(raw);
    if (parsed === null) {
        return [];
    }
    return parsed;
}

export function saveRecentlySubmittedEnkeltIndberetning(model: EnkeltindberetningModel, reportingYear: string, reportingId: number) {
    const recentIndberetningModel = makeRecentIndberetningModel(model, reportingYear, reportingId);
    let recentlySubmitted = getRecentlySubmittedEnkeltIndberetninger();
    recentlySubmitted = filterRejected(recentlySubmitted);
    addOrUpdate(recentlySubmitted, recentIndberetningModel);
    saveRecentlySubmittedEnkeltIndberetningList(recentlySubmitted);
}

function addOrUpdate(recentlySubmitted: RecentIndberetningModel[], recentIndberetningModel: RecentIndberetningModel) {
    const index = recentlySubmitted.findIndex(x => x.reportingId === recentIndberetningModel.reportingId);
    if (index > -1) {
        recentlySubmitted[index] = recentIndberetningModel;
    } else {
        recentlySubmitted.unshift(recentIndberetningModel);
    }
}

export function saveRecentlySubmittedEnkeltIndberetningList(recentlySubmitted: RecentIndberetningModel[]) {
    setRecentSubmissions(JSON.stringify(recentlySubmitted));
}

export function clearRecentlySubmittedEnkeltIndberetninger() {
    clearRecentSubmissions();
}

function filterRejected(indberetninger: RecentIndberetningModel[]): RecentIndberetningModel[] {
    return indberetninger.filter(i => i.status !== 'Afvist');
}

function makeRecentIndberetningModel(model: EnkeltindberetningModel, reportingYear: string, reportingId: number): RecentIndberetningModel {
    const result = new RecentIndberetningModel();
    result.reportingYear = reportingYear;
    result.name = model.accountReport.presentationName();
    result.accountNumber = model.accountReport.accountNumber;
    result.arDocRefId = model.accountReport.docRefIdPrefix + model.accountReport.docRefIdValue;
    result.reportingId = reportingId;
    result.hasControllingPersons = model.hasControllingPersons();
    if (result.hasControllingPersons) {
        for (const cp of model.controllingPersons) {
            result.controllingPersonNames.push(cp.presentationName());
        }
    }
    return result;
}
