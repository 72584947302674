import { Fejl } from "../../../shared/models/fejl.model";
import { hasValidEmptyValue, validGiin, validTin } from "../../../shared/utils/validation-utils";
import { AccountHolderType } from "../../models/account-holder-type";
import { AccountReportModel } from "../../models/account-report.model";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { IdNumberModel } from "../../models/id-number.model";
import { IdNumberValidator } from "../../models/id-number.validator";
import { IndividualAccountHolderModel } from "../../models/individual-account-holder.model";
import { OrganisationAccountHolderModel } from "../../models/organisation-account-holder.model";

export class KontoEjerValidatorFatca {

    static validate(model: EnkeltindberetningModel): Fejl[] {
        return KontoEjerValidatorFatca.validateAccountReport(model.accountReport)
            .concat(KontoEjerValidatorFatca.validateDocRefIds(model));
    }

    static validateDocRefIds(model: EnkeltindberetningModel): Fejl[] {
        const errors: Fejl[] = [];
        if (model.docRefIdValue === model.accountReport.docRefIdValue) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.DUPLICATE_DOCREFID"));
        }
        return errors;
    }

    static validateAccountReport(model: AccountReportModel): Fejl[] {
        if (model.accountHolderType === AccountHolderType.Virksomhed) {
            return KontoEjerValidatorFatca.validateVirksomhedAccountHolder(
                model.organisationAccountHolder,
                model.hasControllingPersons
            );
        } else {
            return KontoEjerValidatorFatca.validateIndividualAccountHolder(model.individualAccountHolder);
        }
    }

    static validateVirksomhedAccountHolder(model: OrganisationAccountHolderModel, hasSubstantialOwners: boolean): Fejl[] {
        return KontoEjerValidatorFatca.validateVirksomhedIdNumbers(model.idNumbers, hasSubstantialOwners);
    }

    static validateIndividualAccountHolder(model: IndividualAccountHolderModel): Fejl[] {
        const errors: Fejl[] = [];
        if (!model.idNumbers.some(id => id.issuedBy === 'US')) {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.FATCA_ISSUEDBY"));
        }

        return errors.concat(IdNumberValidator.validateFatcaIndividualIdNumbers(model.idNumbers));
    }

    static validateVirksomhedIdNumbers(idNumbers: IdNumberModel[], hasSubstantialOwners: boolean): Fejl[] {
        if (idNumbers.some(
            idNumber =>
                idNumber.issuedBy === "US"
                && !hasValidEmptyValue(idNumber.value, hasSubstantialOwners)
                && !validTin(idNumber.value)
                && !validGiin(idNumber.value))
        ) {
            return [new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.FATCA_INVALID_TIN")];
        }
        return [];
    }
}
