import { RouterModule } from "@angular/router";
import { EnkeltindberetningIndberetningComponent } from "./components/enkeltindberetning-indberetning/enkeltindberetning-indberetning.component";
import { EnkeltindberetningKontoejerComponent } from "./components/enkeltindberetning-kontoejer/enkeltindberetning-kontoejer.component";
import { EnkeltindberetningKontrollerendePersonComponent } from "./components/enkeltindberetning-kontrollerende-person/enkeltindberetning-kontrollerende-person.component";
import { EnkeltindberetningModtagetComponent } from "./components/enkeltindberetning-modtaget/enkeltindberetning-modtaget.component";

export const enkeltRoutes = RouterModule.forChild([
    {
        path: 'enkeltindberetning',
        component: EnkeltindberetningIndberetningComponent
    },
    {
        path: 'enkeltindberetning/kontoejer',
        component: EnkeltindberetningKontoejerComponent
    },
    {
        path: 'enkeltindberetning/kontrollerende-person',
        component: EnkeltindberetningKontrollerendePersonComponent
    },
    {
        path: 'enkeltindberetning/modtaget',
        component: EnkeltindberetningModtagetComponent
    }
]);
