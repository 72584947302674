import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: "skat-ja-nej-input-felt",
    templateUrl: 'ja-nej-input-felt.component.html',
    providers: []
})

export class JaNejInputFeltComponent {
    feltIdJa: string;
    feltIdNej: string;
    indexString: string = "";
    valg: boolean;
    oldValue: boolean;

    @Output() modelChange = new EventEmitter();

    @Input() index: number;
    @Input() feltId: string;
    @Input() required: boolean;

    @Input()
    get model() {
        return this.valg;
    }

    set model(val: boolean) {
        this.valg = val;
        this.modelChange.emit(this.valg);
    }

    ngOnInit() {
        if (this.index != undefined) {
            this.indexString = "_" + this.index;
        }
        this.feltIdJa = this.feltId + "_Ja" + this.indexString;
        this.feltIdNej = this.feltId + "_Nej" + this.indexString;
    }

    ngDoCheck() {
        if (this.model !== this.oldValue && this.oldValue == null) {
            this.oldValue = this.model;
            jQuery("#" + this.feltId + "_Container_" + this.index).removeClass("skts-has-option-error");
            jQuery("#" + this.feltId + "_ErrorMessage_" + this.index).removeClass("show");
        }
    }
}
