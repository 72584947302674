export const ctrlgPersonTypes = [
    { "value":"CRS801", "key": "CRS801 - CP of legal person - ownership" },
    { "value":"CRS802", "key": "CRS802 - CP of legal person - other means" },
    { "value":"CRS803", "key": "CRS803 - CP of legal person - senior managing official" },
    { "value":"CRS804", "key": "CRS804 - CP of legal arrangement - trust - settlor" },
    { "value":"CRS805", "key": "CRS805 - CP of legal arrangement - trust - trustee" },
    { "value":"CRS806", "key": "CRS806 - CP of legal arrangement - trust - protector" },
    { "value":"CRS807", "key": "CRS807 - CP of legal arrangement - trust - beneficiary" },
    { "value":"CRS808", "key": "CRS808 - CP of legal arrangement - trust - other" },
    { "value":"CRS809", "key": "CRS809 - CP of legal arrangement - other - settlor-equivalent" },
    { "value":"CRS810", "key": "CRS810 - CP of legal arrangement - other - trustee-equivalent" },
    { "value":"CRS811", "key": "CRS811 - CP of legal arrangement - other - protector-equivalent" },
    { "value":"CRS812", "key": "CRS812 - CP of legal arrangement - other - beneficiary-equivalent" },
    { "value":"CRS813", "key": "CRS813 - CP of legal arrangement - other - other-equivalent" }
];
