import { RouterModule } from "@angular/router";
import { NulindberetningIndberetComponent } from "./components/indberet/nulindberetning-indberet.component";
import { NulindberetningModtagetComponent } from "./components/modtaget/nulindberetning-modtaget.component";

export const nulindberetningRouting = RouterModule.forChild([
    {
        path: 'nulindberetning',
        component: NulindberetningIndberetComponent
    },
    {
        path: 'nulindberetning/modtaget',
        component: NulindberetningModtagetComponent
    }
]);
