import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IndberetningstypeAwareness } from '../../helpers/Indberetningstype-awareness';
import { TranslationService } from '../translate/translation.service';

@Injectable()
export class TitleService extends IndberetningstypeAwareness {

    constructor(private translationService: TranslationService, private angularTitleService: Title) {
        super();
    }

    /**
     * Resolves the text for the provided key and sets the browser title bar to this value
     * @param textKey The key for the title
     * @param interpolationParams Parameters matching the key text template
     * @return the text that was set as title in the browser
     */
    setTitleFromKey(textKey: string, interpolationParams: any = {indberetningstype: this.uppercaseTitle()}): string {
        let titleValue: string = '';
        this.translationService.environmentAwareTranslate(
            textKey,
            interpolationParams
        ).subscribe((res: string) => {
            titleValue = res;
        });
        this.angularTitleService.setTitle(titleValue);
        return titleValue;
    }

    /**
     * Sets the browser title bar to the reporting type currently in scope ('CRS' or 'FATCA')
     * @return the text that was set as title in the browser
     */
    setTitleToReportingType() {
        const titleValue: string = this.uppercaseTitle();
        this.angularTitleService.setTitle(titleValue);
        return titleValue;
    }
}
