/**
 * Model to hold the values that the user want to filter the table on.
 */
export class HistoryFilterValuesModel {
    year: string;
    messageRefId: string;
    status: string;

    constructor() {
        this.year = '';
        this.messageRefId = '';
        this.status = '';
    }

    /**
     * Returns the filter value for the year.
     *
     * If year equals the value for all years then null is returned.
     *
     * @param allValue The value for all years.
     */
    public getYearFilterValue(allValue: string): string {
        if (this.year == null) {
            return null;
        }

        if (this.year.trim() === "") {
            return null;
        }

        if (this.year.trim() === allValue) {
            return null;
        }

        return this.year;
    }

    /**
     * Returns the filter value for the message ref id.
     */
    public getMessageRefIdFilterValue(): string {
        if (this.messageRefId == null) {
            return null;
        }

        if (this.messageRefId.trim() === "") {
            return null;
        }

        return this.messageRefId;
    }

    /**
     * Returns the filter value for the status.
     *
     * If year equals the value for all statuses then null is returned.
     *
     * @param allValue The value for all statuses.
     */
    public getStatusFilterValue(allValue: string): string {
        if (this.status == null) {
            return null;
        }

        if (this.status.trim() === "") {
            return null;
        }

        if (this.status.trim() === allValue) {
            return null;
        }

        return this.status;
    }
}
