import { AddressModel } from "./address.model";
import { IdNumberModel } from './id-number.model';

/**
 * Shared Account Holder fields
 */
export abstract class AccountHolderModel {
    resCountryCodes: string[];
    idNumbers: IdNumberModel[]; // Ex. TIN number
    addresses: AddressModel[];

    constructor() {
        this.resCountryCodes = [""];
        this.idNumbers = [new IdNumberModel()];
        this.addresses = [new AddressModel()];
    }

    abstract presentationName(): string;
}
