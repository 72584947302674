import { Fejl } from "../../../shared/models/fejl.model";
import { AccountReportModel } from "../../models/account-report.model";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { ResCountryCodeValidator } from "../../models/res-country-code.validator";

export class KontoEjerValidatorCrs {

    static validate(model: EnkeltindberetningModel): Fejl[] {
        return KontoEjerValidatorCrs.validateAccountReport(model.accountReport);
    }

    static validateAccountReport(model: AccountReportModel): Fejl[] {
        const errors: Fejl[] = [];
        if (model.closedAccount && model.accountBalance !== "0") {
            errors.push(new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.CLOSEDACCOUNT_BALANCE"));
        }
        if (!model.undocumentedAccount) {
            const resCountryCodes = model.erPerson() ? model.individualAccountHolder.resCountryCodes : model.organisationAccountHolder.resCountryCodes;
            errors.concat(ResCountryCodeValidator.validate(resCountryCodes));
        }
        return errors;
    }
}
