import { IndberetningstypeAwareness } from '../../shared/helpers/Indberetningstype-awareness';
import {
    TIDLIGSTE_AAR_FOR_INDBERETNING_CRS,
    TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA
} from '../../shared/utils/calculations-utils';

/**
 * Model to hold the possible values that can be used for filter fields with a list of possible values.
 */
export class HistoryFilterPossibleValuesModel {
    years: string[];
    statuses: string[];

    constructor() {
        this.years = this.generatePossibleYears();
        this.statuses = this.generatePossibleStatuses();
    }

    private generatePossibleYears(): string[] {
        let currentYear:number = new Date().getFullYear()-1;

        const result = [];
        const earliestYear = IndberetningstypeAwareness.statics.erCrs() ? TIDLIGSTE_AAR_FOR_INDBERETNING_CRS : TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA;
        while (currentYear >= earliestYear) {
            result.push(currentYear.toString());
            currentYear--;
        }
        return result;
    }

    private generatePossibleStatuses(): string[] {
        return ['Godkendt', 'Afvist', 'Modtaget'];
    }
}
