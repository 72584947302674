import { Injectable } from "@angular/core";
import 'rxjs/add/observable/of';
import { Observable } from "rxjs/Observable";
import { getLanguageCode, textKeysVisible } from "../../utils/session-storage-utils";
import { isDefined } from "../../utils/text-utils";
import { DK_LANGUAGE_CODE, LanguageFileService } from "../language-file/language-file.service";

@Injectable()
export class TranslationService {

    constructor(private languageFileService: LanguageFileService) {
    }

    environmentAwareTranslate(textKey: string, interpolationParams: any = null): Observable<string> {
        let languageCode = getLanguageCode();
        if (languageCode == undefined) {
            languageCode = DK_LANGUAGE_CODE;
        }

        return this.languageFileService.getLanguageFile(languageCode)
            .map((response) => {
                const text = response[textKey];
                if (!isDefined(text)) {
                    return textKey;
                }
                return this.prepareText(textKey, text, interpolationParams);
            }).catch((e) => {
                    return textKey;
                }
            );
    }

    prepareText(textKey: string, text: string, interpolationParams: any): string {
        const parsedText = this.interpolateText(text, interpolationParams);
        return this.prefixWithKey(textKey, parsedText, textKeysVisible());
    }

    interpolateText(text: string, interpolationParams: any): string {
        let result = text;
        if (isDefined(interpolationParams)) {
            for (const paramKey of Object.keys(interpolationParams)) {
                const paramValue = interpolationParams[paramKey];
                const textKey = "{{" + paramKey + "}}";
                while (result.indexOf(textKey) !== -1) {
                    result = result.replace("{{" + paramKey + "}}", paramValue);
                }
            }
        }
        return result;
    }

    prefixWithKey(textKey: string, text: string, shouldShowTextKeys: boolean) {
        return (shouldShowTextKeys ? "[" + textKey + "] " : "") + text;
    }
}
