import { AccountHolderModel } from './account-holder.model';

export class IndividualAccountHolderModel extends AccountHolderModel {
    firstName: string;
    lastName: string;
    birthDate: string;

    constructor() {
        super();
    }

    presentationName(): string {
        return `${this.lastName}, ${this.firstName}`;
    }
}
