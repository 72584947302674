import { IndberetningstypeAwareness } from "../../shared/helpers/Indberetningstype-awareness";
import { AccountHolderType } from "./account-holder-type";
import { ControllingPersonModel } from "./controlling-person.model";
import { IndividualAccountHolderModel } from "./individual-account-holder.model";
import { OrganisationAccountHolderModel } from "./organisation-account-holder.model";
import { PaymentModel } from "./payment.model";

export class AccountReportModel {

    docTypeIndic: string = IndberetningstypeAwareness.statics.erFatca() ? "FATCA1" : "OECD1";
    docRefIdPrefix: string = "";
    docRefIdValue: string = "";

    // Account Holder information
    accountHolderType: AccountHolderType; // Radio buttons: Privat or Virksomhed
    hasControllingPersons: boolean;
    individualAccountHolder: IndividualAccountHolderModel;
    organisationAccountHolder: OrganisationAccountHolderModel;
    controllingPersons: ControllingPersonModel[];

    // Account info shared between CRS and FATCA
    accountNumber: string;
    accountNumberType: string = "";
    accountBalance: string;
    accountCurrency: string = "";
    closedAccount: boolean;

    // CRS specific account info
    undocumentedAccount: boolean;
    dormantAccount: boolean;

    //Payments
    payments: PaymentModel;

    constructor() {
        this.init();
    }

    init() {
        this.individualAccountHolder = new IndividualAccountHolderModel();
        this.organisationAccountHolder = new OrganisationAccountHolderModel();
        this.controllingPersons = [ new ControllingPersonModel() ];
        this.accountHolderType = AccountHolderType.Person;
        this.payments = new PaymentModel();
        this.setFatcaResCountryCodes();
    }

    setFatcaResCountryCodes() {
        if(!IndberetningstypeAwareness.statics.erFatca()) {
          return;
        }
        if(this.erPerson()) {
            this.individualAccountHolder.resCountryCodes = ["US"];
            this.organisationAccountHolder.resCountryCodes = [""];
        } else if (this.erVirksomhed()) {
            this.individualAccountHolder.resCountryCodes = [""];
            this.organisationAccountHolder.resCountryCodes = ["US"];
        }
    }

    get publicAccountHolderType() {
        return this.accountHolderType;
    }

    set publicAccountHolderType(value: AccountHolderType) {
        this.accountHolderType = value;
        this.setFatcaResCountryCodes();
    }

    public erVirksomhed() {
        return this.accountHolderType === AccountHolderType.Virksomhed;
    }

    public erPerson() {
        return this.accountHolderType === AccountHolderType.Person;
    }

    presentationName(): string {
        return this.erVirksomhed() ?
            this.organisationAccountHolder.presentationName() :
            this.individualAccountHolder.presentationName();
    }
}
