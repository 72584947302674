import { Component, Input } from '@angular/core';
import { maxStringLength } from "../../../../shared/models/model-constants";
import { CountryService } from "../../../../shared/services/country/country.service";
import { AddressModel } from '../../../models/address.model';

@Component({
    selector: "skat-address-input",
    templateUrl: "./address.component.html",
    styleUrls: ["../../../../shared/css/repeterende-grupper.css"],
    providers: []
})

export class AddressComponent {
    @Input() model: AddressModel;
    @Input() index: number;
    @Input() addresses: AddressModel[];

    constructor(private countryService: CountryService) {
    }

    removeAdresse(index:number) {
        this.addresses.splice(index, 1);
    }

    countries() {
        return this.countryService.getCountries();
    }

    maxStringLength(): number {
        return maxStringLength;
    }
}
