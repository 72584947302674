import { AfterViewInit, Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ctrlgPersonTypes } from "../../../shared/datalists/ctrlg-person-type";
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { ErrorService } from "../../../shared/services/error/error.service";
import { FormSubmissionService } from "../../../shared/services/form-submission/form-submission.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { saveRecentlySubmittedEnkeltIndberetning } from '../../../shared/utils/recent-indberetninger-helper';
import { waitForPligtigAndPerformAction } from "../../../shared/utils/session-storage-utils";
import { genererUrlTilEnkeltindberetning } from "../../../shared/utils/url-utils";
import { ControllingPersonModel } from '../../models/controlling-person.model';
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";
import { EnkeltindberetningFlowService } from "../../services/enkeltindberetning-flow/enkeltindberetning-flow.service";
import { KontoEjerValidatorService } from "../../services/validation/kontoejer-validator.service";
import { KontrollerendePersonValidatorService } from '../../services/validation/kontrollerende-person-validator.service';

@Component({
    templateUrl: './enkeltindberetning-kontrollerende-person.component.html',
    providers: [FormSubmissionService, KontrollerendePersonValidatorService, KontoEjerValidatorService],
    styleUrls: ['../../../shared/css/repeterende-grupper.css']
})
@Injectable()
export class EnkeltindberetningKontrollerendePersonComponent extends IndberetningstypeAwareness implements OnInit, AfterViewInit {

    model: EnkeltindberetningModel;

    private successUrl = "/enkeltindberetning/modtaget";

    flow: EnkeltindberetningFlowService;

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private formSubmissionService: FormSubmissionService,
                public errors: ErrorService,
                private validator: KontrollerendePersonValidatorService,
                private enkeltindberetningFlowService: EnkeltindberetningFlowService,
                private titleService: TitleService) {
        super();
        this.flow = enkeltindberetningFlowService;
        this.model = this.flow.getCurrentModel();

        if (this.erFatca()) {
            this.model.controllingPersons.forEach((controllingPerson) => {
                controllingPerson.individualAccountHolder.idNumbers.forEach((idNumber) => {
                    if (!idNumber.issuedBy) { idNumber.issuedBy = 'US'; }
                });
            });
        }
        if (this.flow.reportingYear === "" || this.model.name === "") {
            router.navigate(["/enkeltindberetning"]);
        }
        formSubmissionService.backendUrlFunc = genererUrlTilEnkeltindberetning;
    }

    submit() {
        this.flow.saveInitielModel(this.model);
        this.errors.reset();

        const modelToSend: any = this.model;

        // Validate and submit reporting
        modelToSend.reportingYear = this.flow.reportingYear;
        modelToSend.messageRefIdPrefix = this.flow.messageRefIdPrefix;
        modelToSend.messageRefIdValue = this.flow.messageRefIdValue;
        this.formSubmissionService.validateAndPost(this.validator, modelToSend, this.erFatca());

    }

    ngAfterViewInit() {
        if (this.erCrs()) {
            window.document.getElementById('ctrlperson_0').focus();
        } else {
            window.document.getElementById('foedselsdato_0').focus();
        }
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    ngOnInit(): void {
        this.formSubmissionService.init();
        this.errors.reset();
        this.subscribePaaSuccess();
        this.subscribePaaFejl();

        waitForPligtigAndPerformAction(() => {
            page.hideLoader();
        }, () => { });
    }

    ngOnDestroy() {
        this.flow.saveInitielModel(this.model);
    }

    subscribePaaSuccess() {
        this.formSubmissionService.completed.subscribe(
            (svar: any) => {
                saveRecentlySubmittedEnkeltIndberetning(this.model, this.flow.reportingYear, svar.data.attributes.reportingId);
                page.hideLoader();
                this.router.navigate([this.successUrl]);
            }
        );
    }

    subscribePaaFejl() {
        this.formSubmissionService.completedWithErrors.subscribe(
            (fejl: Fejl[]) => {
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
            }
        );
    }

    ctrlgPersonTypes() {
        return ctrlgPersonTypes;
    }

    hasControllingPersons(): boolean {
        return this.model.hasControllingPersons();
    }

    addControllingPerson() {
        const controllingPerson = new ControllingPersonModel();
        if (this.erFatca()) {
            controllingPerson.individualAccountHolder.idNumbers.forEach((idNumber) => {
                if (!idNumber.issuedBy) { idNumber.issuedBy = 'US'; }
            });
        }
        this.model.controllingPersons.push(controllingPerson);
    }

    removeControllingPerson(index: number) {
        this.model.controllingPersons.splice(index, 1);
    }

    tilbage() {
        this.router.navigate(["/enkeltindberetning/kontoejer", {
            persist: "true"
        }]);
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('ENKELTINDBERETNING.INDBERETNING.OVERSKRIFT');
    }
}
