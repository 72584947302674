import { RouterModule } from "@angular/router";
import { FiloverfoerselIndberetComponent } from "./components/filoverfoersel-indberet/filoverfoersel-indberet.component";
import { FiloverfoerselModtagetComponent } from "./components/filoverfoersel-modtaget/filoverfoersel-modtaget.component";

export const filuploadRoutes = RouterModule.forChild([
    {
        path: 'filupload',
        component: FiloverfoerselIndberetComponent
    },
    {
        path: 'filupload/modtaget',
        component: FiloverfoerselModtagetComponent
    },
]);
