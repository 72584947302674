import { Component } from '@angular/core';
import { MessageStatusHelper } from "../../../shared/helpers/message-status-helper";
import { Fejl } from "../../../shared/models/fejl.model";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { formatDate, isDefined } from "../../../shared/utils/text-utils";
import { GetRecordsModel } from '../../../tidligere-indberetninger/models/get-records.model';
import { HistoryRecordModel } from "../../../tidligere-indberetninger/models/history-record.model";
import { HentFilService } from "../../../tidligere-indberetninger/services/hent-fil/hent-fil.service";
import { HentHistorikService } from "../../../tidligere-indberetninger/services/hent-historik/hent-historik.service";
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";

@Component({
    templateUrl: './filoverfoersel-modtaget.component.html',
    providers: [
        HentHistorikService, HentFilService
    ]
})

export class FiloverfoerselModtagetComponent extends MessageStatusHelper {

    model: FiloverfoerselModel;
    reportings: HistoryRecordModel[];
    reportingToShow: HistoryRecordModel = null;
    errorService: ErrorService;
    refreshInterval: number = 1000;
    formatDate = formatDate;
    isDefined = isDefined;

    constructor(public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private filoverfoerselModel: FiloverfoerselModel,
                private historikService: HentHistorikService,
                private errors: ErrorService,
                protected hentFilService: HentFilService,
                private translationService: TranslationService,
                private titleService: TitleService) {
        super(hentFilService);
        this.model = filoverfoerselModel;
        this.errorService = errors;
        this.errorService.reset();
    }

    ngOnInit() {
        if (this.model.oprettelsesid !== null) {
            this.subscribePaaHistorik();

            this.translationService.environmentAwareTranslate('FILOVERFOERSEL.MODTAGET.SPINNER').subscribe((spinnerTekst: string) => {
                page.showLoader(spinnerTekst);
                this.historikService.getReports();
            });
        }
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    subscribePaaHistorik() {
        this.historikService.reportListCompleted.subscribe(
            (reportings: GetRecordsModel) => {
                page.hideLoader();
                this.reportings = reportings.records;
                this.reportingToShow = this.getReportingToShow(this.model.oprettelsesid);

                if (this.reportingsHaveUnfinishedBusiness(this.reportings)) {
                    this.scheduleRefresh();
                }
            }
        );

        this.historikService.responseError.subscribe(
            (fejl: Fejl) => {
                if (fejl) {
                    page.hideLoader();
                    this.errorService.tilfoejFejl([fejl]);
                }
            }
        );
    }

    scheduleRefresh() {
        setTimeout(() => {
            this.historikService.getReports();
        }, this.refreshInterval);
    }

    ngOnDestroy() {
        this.model.init();
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('FILOVERFOERSEL.MODTAGET.OVERSKRIFT');
    }
}
