import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { nyRettighedsFejl, nyStandardFejl } from "../../utils/fejl-utils";
import { accessCheckUrl, accessWriteCheckUrl } from "../../utils/url-utils";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class AccessCheckService {

    router: Router;

    accessCheck: Subject<any> = new Subject<any>();
    accessCheckFejl: Subject<any> = new Subject<any>();
    writeAccessCheck: Subject<any> = new Subject<any>();
    writeAccessCheckFejl: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    checkAccess(loginContextHasChanged: Boolean) {
        this.http.get(accessCheckUrl(), httpOptions)
            .subscribe(
                (data: any) => {
                    this.accessCheck.next(loginContextHasChanged);
                }, (error: any) => {
                    this.accessCheckFejl.next(nyRettighedsFejl());
                }
            );
        this.checkWriteAccess();
    }

    checkWriteAccess() {
        this.http.get(accessWriteCheckUrl(), httpOptions)
            .subscribe(
                (data: any) => {
                    this.writeAccessCheck.next(true);
                }, (error: any) => {
                    this.handleError(error);
                }
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.writeAccessCheckFejl.next(nyStandardFejl());
        } else {
            // The backend returned an unsuccessful response code.
            this.writeAccessCheck.next(false);
        }
    }
}
