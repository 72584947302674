import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject , Subscription } from 'rxjs';
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { generateErrorItems } from "../../../shared/utils/fejl-utils";
import { splitTextAndGetLast } from "../../../shared/utils/text-utils";
import { genererUrlTilFiluploadIndberetning } from "../../../shared/utils/url-utils";
import { FiloverfoerselModel } from "../../models/filoverfoersel.model";
import { FiluploadFactory } from "./filupload.factory";

@Injectable()
export class FiluploadService {

    private method: string = 'POST';
    public isUploading: boolean;
    public isSuccess: boolean;
    public isError: boolean;
    public errors: Array<Fejl>;
    model: FiloverfoerselModel;

    onErrorSubscription:Subscription;
    onSuccessSubscription:Subscription;

    @Output() progressEvent = new EventEmitter();
    uploadCompleted: Subject<any> = new Subject<any>();
    uploadCompletedWithError: Subject<any> = new Subject<any>();

    constructor(private filoverfoerselModel: FiloverfoerselModel, private filuploadFactory: FiluploadFactory) {
        this.model = filoverfoerselModel;
        this.onErrorSubscription = this.filuploadFactory.onError.subscribe((error: [any, any, any]) => error ? this.onErrorItem(error[0], error[1], error[2]) : void(0));
        this.onSuccessSubscription = this.filuploadFactory.onSuccess.subscribe((success: [any, any, any]) => success ? this.onSuccessItem(success[0], success[1], success[2]) : void(0));
    }

    ngOnDestroy() {
        this.onErrorSubscription.unsubscribe();
        this.onSuccessSubscription.unsubscribe();
    }

    public uploadFil(): void {
        if (this.isUploading) {
            return;
        }
        this.init();
        this.isUploading = true;
        this.xhrTransport();
    }

    private init() {
        this.isUploading = false;
        this.isSuccess = false;
        this.isError = false;
    }

    private getUploadUrl(): string {
        return genererUrlTilFiluploadIndberetning();
    }

    public onSuccessItem(response: any, status: any, headers: any) {
        this.isUploading = false;
        this.isSuccess = true;
        this.onSuccess(response, status, headers);
    }

    public onErrorItem(response: any, status: any, headers: any) {
        this.isUploading = false;
        this.isError = true;
        this.errors = generateErrorItems(response, status);
        this.uploadCompletedWithError.next(this.errors);
    }

    public onSuccess(response: any, status: any, headers: any) {
        this.model.oprettelsesid = Number.parseInt(splitTextAndGetLast(headers.location, "/"));
        this.uploadCompleted.next(true);
    }

    private xhrTransport() {

        const item: File = this.filoverfoerselModel.fil;
        const xhr = this.filuploadFactory.createXMLHttpRequest(this.progressEvent);

        if (typeof item.size !== 'number') {
            throw new TypeError('The file specified is no longer valid');
        }

        xhr.open(this.method, this.getUploadUrl(), true);
        const schemaRef = IndberetningstypeAwareness.statics.erCrs() ? '"urn:oecd:ties:crs:v2"' : '"urn:oecd:ties:fatca:v2"';
        xhr.setRequestHeader("Content-Type", 'application/xml; schema=' + schemaRef);
        xhr.send(item);
    }
}
