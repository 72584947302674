import { Fejl } from "../../shared/models/fejl.model";
import { IdNumberValidator } from "./id-number.validator";
import { IndividualAccountHolderModel } from "./individual-account-holder.model";
import { ResCountryCodeValidator } from "./res-country-code.validator";

export class IndividualAccountHolderValidator {

    static validate(model: IndividualAccountHolderModel): Fejl[] {
        return this.validateIdNumbers(model)
            .concat(this.validateResCountryCodes(model));
    }

    private static validateIdNumbers(model: IndividualAccountHolderModel): Fejl[] {
        return IdNumberValidator.validateIssuedBy(model.idNumbers);
    }

    private static validateResCountryCodes(model: IndividualAccountHolderModel): Fejl[] {
        return ResCountryCodeValidator.validate(model.resCountryCodes);
    }
}
