import { Component } from "@angular/core";
import { NavigationStart, Router } from '@angular/router';
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { RecentIndberetningModel } from '../../../shared/models/recent-indberetning-model';
import { ErrorService } from "../../../shared/services/error/error.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { getStatusColorClass } from '../../../shared/utils/css-utils';
import {
    clearRecentlySubmittedEnkeltIndberetninger,
    getRecentlySubmittedEnkeltIndberetninger,
    saveRecentlySubmittedEnkeltIndberetningList
} from "../../../shared/utils/recent-indberetninger-helper";
import { waitForPligtigAndPerformAction } from '../../../shared/utils/session-storage-utils';
import { formatDate, textContainsSubtext } from "../../../shared/utils/text-utils";
import { GetRecordsModel } from '../../../tidligere-indberetninger/models/get-records.model';
import { HistoryRecordModel } from '../../../tidligere-indberetninger/models/history-record.model';
import { HentFilService } from '../../../tidligere-indberetninger/services/hent-fil/hent-fil.service';
import { HentHistorikService } from '../../../tidligere-indberetninger/services/hent-historik/hent-historik.service';
import { EnkeltindberetningFlowService } from "../../services/enkeltindberetning-flow/enkeltindberetning-flow.service";

const REFRESH_INTERVAL_MS: number = 2 * 1000;

@Component({
    templateUrl: './enkeltindberetning-modtaget.component.html',
    providers: [HentHistorikService, ErrorService, TranslationService, HentFilService]
})
export class EnkeltindberetningModtagetComponent extends IndberetningstypeAwareness {

    cpPostfix:string;

    locallySavedReportings: RecentIndberetningModel[];
    reportingsToShow: RecentIndberetningModel[];

    formatDate = formatDate;
    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private historikService: HentHistorikService,
                private translationService: TranslationService,
                private errorService: ErrorService,
                private flowService:EnkeltindberetningFlowService,
                private hentFilService: HentFilService,
                private titleService: TitleService) {
        super();
    }

    hasRejected(): boolean {
        return this.reportingsToShow.some(r => r.status === 'Afvist');
    }

    ngOnInit() {
        this.reportingsToShow = [];
        this.locallySavedReportings = [];
        this.subscribeToReportChanges();
        this.subscribeToRouterNavigation();
        this.subscribeToFejlVedFilDownload();

        this.translationService.environmentAwareTranslate('GENERELT.KONTROLLERENDEPERSON.POSTFIX').subscribe((res: string) => {
            this.cpPostfix = res;
        });
        this.translationService.environmentAwareTranslate('OVERSIGT.HENTERINDHOLD').subscribe((res: string) => {
            page.showLoader(res);
            waitForPligtigAndPerformAction(() => {
                this.hentReports();
                this.locallySavedReportings = getRecentlySubmittedEnkeltIndberetninger();
            }, () => {
                page.hideLoader();
            });
        });
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    updateReportingsToShow(reportingsFromBackend: HistoryRecordModel[], locallySavedReportings: RecentIndberetningModel[]) {
        const reportingsToShow = [];
        for(const reporting of locallySavedReportings){
            for(const historyRecord of reportingsFromBackend){
                if(reporting.reportingId === this.parseReportingId(historyRecord.reportingLink)) {
                    reporting.date = historyRecord.date;
                    reporting.status = historyRecord.status;
                    reporting.statusLink = historyRecord.statusLink;
                    reportingsToShow.push(reporting);
                }
            }
        }
        this.reportingsToShow = reportingsToShow;
        saveRecentlySubmittedEnkeltIndberetningList(reportingsToShow);
    }

    parseReportingId(reportingLink: string): number {
        const splitted = reportingLink.split("/");
        return parseInt(splitted[splitted.length - 1]);
    }

    hentReports() {
        this.historikService.getReports();
        this.errorService.reset();
    }

    hentFil(statusLink: string, messageRefId: string) {
        this.hentFilService.hentFil(statusLink, messageRefId);
    }

    subscribeToFejlVedFilDownload() {
        this.hentFilService.fejlVedHentningAfFil.subscribe(
            (fejl: any) => {
                this.errorService.resetOgTilfoejFejlbesked("OVERSIGT.FEJL_VED_FIL_DOWNLOAD");
            });
    }

    subscribeToReportChanges() {
        this.historikService.reportListCompleted.subscribe(
            (reportings: GetRecordsModel) => {
                page.hideLoader();
                this.updateReportingsToShow(reportings.records, this.locallySavedReportings);
                if(this.hasUnfinishedReportings()) {
                    this.scheduleRefresh();
                }
            }
        );

        this.historikService.responseError.subscribe(
            (fejl: Fejl) => {
                if (fejl) {
                    page.hideLoader();
                    this.errorService.tilfoejFejl([fejl]);
                }
            }
        );
    }

    scheduleRefresh() {
        setTimeout(() => {
            this.hentReports();
        }, REFRESH_INTERVAL_MS);
    }

    hasUnfinishedReportings() {
        return this.reportingsToShow.some(r => r.status.toLowerCase() === "modtaget");
    }

    newIndberetningClicked() {
        const model = this.flowService.getCurrentModel();
        if (this.erCrs()) {
            this.router.navigate(["/enkeltindberetning", {
                "fi": model.docRefIdPrefix + model.docRefIdValue,
                "year": this.flowService.reportingYear
            }]);
        } else {
            this.router.navigate(["/enkeltindberetning"]);
        }

    }

    getStatusColor(status: string) {
        return getStatusColorClass(status);
    }

    subscribeToRouterNavigation() {
      this.router.events.subscribe((val) => {
          if(val instanceof NavigationStart){
              if(!textContainsSubtext(val.url,"/enkeltindberetning")){
                  clearRecentlySubmittedEnkeltIndberetninger();
              }
          }
      });
    }

    get lastReportHadControllingPerson(): boolean {
        return this.locallySavedReportings.length > 0 && this.locallySavedReportings[0].hasControllingPersons;
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('ENKELTINDBERETNING.INDBERETNING.OVERSKRIFT');
    }

    nameTooltip(report: RecentIndberetningModel): string {
        let tooltip: string = report.name;
        let cpName: string;
        for (cpName of report.controllingPersonNames) {
            // See https://html.spec.whatwg.org/#the-title-attribute concerning the use of newlines
            tooltip = tooltip + `\n${cpName}${this.cpPostfix}`;
        }
        return tooltip;
    }
}
