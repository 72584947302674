import { HttpClient, HttpClientModule, HttpXhrBackend } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { appRouting } from "./app.routing";
import { EnkeltindberetningModule } from "./enkeltindberetning/enkeltindberetning.module";
import { EnkeltindberetningFlowService } from "./enkeltindberetning/services/enkeltindberetning-flow/enkeltindberetning-flow.service";
import { FiloverfoerselModule } from "./filoverfoersel/filoverfoersel.module";
import { ForsideComponent } from "./forside/forside.component";
import { NulindberetningModule } from "./nulindberetning/nulindberetning.module";
import { FejlsideComponent } from "./shared/components/fejlside/fejlside.component";
import { RouterComponent } from "./shared/components/router/router.component";
import { SkatHttp } from "./shared/helpers/skat-http";
import { AccessCheckService } from "./shared/services/access-check/access-check.service";
import { ConfigService } from "./shared/services/config/config.service";
import { CountryService, countryServiceFactory } from "./shared/services/country/country.service";
import { ErrorService } from "./shared/services/error/error.service";
import { LanguageFileService } from "./shared/services/language-file/language-file.service";
import { TitleService } from './shared/services/title/title.service';
import { TranslationService } from "./shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "./shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { SharedModule } from "./shared/shared.module";
import {
    clearPligtigOgIndberetter, setTextKeysVisible,
    setUserIsBeingValidated
} from "./shared/utils/session-storage-utils";
import { TidligereIndberetningerModule } from "./tidligere-indberetninger/tidligere-indberetninger.module";

@NgModule({
    imports: [
        BrowserModule,
        appRouting,
        FiloverfoerselModule,
        NulindberetningModule,
        TidligereIndberetningerModule,
        EnkeltindberetningModule,
        HttpClientModule,
        SharedModule
    ],
    declarations: [
        RouterComponent,
        ForsideComponent,
        FejlsideComponent
    ],
    exports: [
        ForsideComponent,
        RouterComponent,
        RouterModule,
        FejlsideComponent,
    ],
    providers: [
        {
            provide: HttpClient, useFactory: httpFactory,
            deps: [HttpXhrBackend]
        },
        ErrorService,
        ConfigService,
        AccessCheckService,
        WriteAccessCheckSubscriberService,
        TranslationService,
        LanguageFileService,
        EnkeltindberetningFlowService,
        TitleService,
        CountryService,
        {
            provide: APP_INITIALIZER,
            useFactory: countryServiceFactory,
            deps: [CountryService],
            multi: true
        }
    ],
    bootstrap: [
        RouterComponent
    ]
 })
export class AppModule {
    constructor() {
        clearPligtigOgIndberetter();
        setTextKeysVisible("false");
        setUserIsBeingValidated(false);
    }
}

export function httpFactory(backend: HttpXhrBackend) {
    return new SkatHttp(backend);
}
