/**
 * Model to hold the values that the user want to filter the table on.
 */
export class OversigtFilterValuesModel {
    year: string;
    accId: string;
    accNumber: string;
    accHolderName: string;

    constructor(year:string = '') {
        this.year = year;
        this.accNumber = '';
        this.accHolderName = '';
        this.accId = '';
    }

    public getHtmlParamString(): string {
        let s = `periode=`;

        if (this.year != null && this.year.trim().length > 0) {
            s = s.concat(this.year);
        }

        if (this.accHolderName != null && this.accHolderName.trim().length > 0) {
             s = s.concat(`&accountHolderName=${this.accHolderName}`);
        }

        if (this.accNumber != null && this.accNumber.trim().length > 0) {
            s = s.concat(`&accountNumber=${this.accNumber}`);
        }

        if (this.accId != null && this.accId.trim().length > 0) {
            s = s.concat(`&arDocRefId=${this.accId}`);
        }
        return s;
    }
}
