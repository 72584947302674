import { HistoryRecordModel } from '../../tidligere-indberetninger/models/history-record.model';
import { OverviewItemModel } from '../../tidligere-indberetninger/models/overview-item.model';
import { IndberetningstypeAwareness } from "../helpers/Indberetningstype-awareness";

export const TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA:number = 2014;
export const TIDLIGSTE_AAR_FOR_INDBERETNING_CRS:number = 2016;
export const MAKS_ANTAL_AAR_BAGUD_TID:number = 5;
/**
 * Beregner de år, det er muligt at indberette for. Reglen er, at:
 * - Der må TIDLIGST indberettes for 2016 for CRS
 * - Der må TIDLIGST indberettes for 2016 for FATCA
 * - Der må indberettes 5 år tilbage i tiden starting from last year - indværende år er IKKE et indberetningsår -,
 *    bortset for nulIndberetning som kan submittes for indværende år (firmalukning eller konkurs) + 5 år tilbage.
 *
 * @returns {Array}
 */

/**
 * includeCurrent=true vil inkludere indeværende år i resultat og dermed returnere op til MAKS_ANTAL_AAR_BAGUD_TID +1 entries
 * @param includeCurrent
 */
export function genererIndberetningsaarListe(includeCurrent:boolean=false): number[] {
    const tilladteAar = [];
    const tidligsteAar = IndberetningstypeAwareness.statics.erCrs() ? TIDLIGSTE_AAR_FOR_INDBERETNING_CRS : TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA;
    const nuvaerendeAar:number = new Date().getFullYear();

    if(includeCurrent) {
        // add current year to array
        tilladteAar.push(nuvaerendeAar);
    }
    let indberetingsAar:number = nuvaerendeAar - 1;
    let maksAntalAarTilbage = MAKS_ANTAL_AAR_BAGUD_TID;

    // add all previous years to array up to max or earliest
    while (maksAntalAarTilbage > 0 && indberetingsAar >= tidligsteAar) {
        tilladteAar.push(indberetingsAar);
        indberetingsAar--;
        maksAntalAarTilbage--;
    }
    return tilladteAar;
}

export function genererIndberetningsaarListeForHistorik(): string[] {
    let nuvaerendeAar:number = new Date().getFullYear()-1;
    const tilladteAar = [];
    const tidligsteAar = IndberetningstypeAwareness.statics.erCrs() ? TIDLIGSTE_AAR_FOR_INDBERETNING_CRS : TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA;
    while (nuvaerendeAar >= tidligsteAar) {
        tilladteAar.push(nuvaerendeAar.toString());
        nuvaerendeAar--;
    }
    return tilladteAar;
}

export function genererFinansielleInstitutter(list:OverviewItemModel[]): string[] {
    const result: string[] = [];
    for (const k of list) {
        if (result.indexOf(k.fiDocRefId) === -1) {
            result.push(k.fiDocRefId);
        }
    }
    return result.sort();
}

export function genererFiGrupper(list:OverviewItemModel[]): string[] {
    const result: string[] = [];
    for (const k of list) {
        if (result.indexOf(k.fiDocRefIdAndNameGroup()) === -1) {
            result.push(k.fiDocRefIdAndNameGroup());
        }
    }
    return result.sort();
}

export function genererStatuserForHistorik(list:HistoryRecordModel[]): string[] {
    const result: string[] = [];
    for (const k of list) {
        if (result.indexOf(k.status) === -1) {
            result.push(k.status);
        }
    }
    return result.sort();
}
