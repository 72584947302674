
export function isDefined(value: string): boolean {
    return !!value;
}

/**
 * @param value the value to test
 * @return true if the value has valid format and is not an invalid combination of characters
 */
export function validTin(value: string): boolean {
    const validFormat: boolean = /^\d{9}$/.test(value) || /^\d{2}[-]\d{7}$/.test(value) || /^\d{3}[-]\d{2}[-]\d{4}$/.test(value);
    const valueWithoutDashes: string = value.split('-').join('');
    const invalidCombination: boolean = ['111111111', '888888888', '999999999'].includes(valueWithoutDashes);
    return validFormat && !invalidCombination;
}

export function validGiin(value: string): boolean {
    return /^[A-NP-Z0-9]{6}[.][A-NP-Z0-Z]{5}[.][A-NP-Z]{2}[.]\d{3}$/.test(value);
}

/**
 * @param value the value to test
 * @param optional indicates whether a value is optional (true) og required (false)
 * @return true if optional and no value, false otherwise
 */
export function hasValidEmptyValue(value: string, optional: boolean = false): boolean {
    return optional && !isDefined(value);
}
