import { AccountHolderModel } from '../../enkeltindberetning/models/account-holder.model';
import { AccountReportModel } from "../../enkeltindberetning/models/account-report.model";
import { AddressModel } from "../../enkeltindberetning/models/address.model";
import { ControllingPersonModel } from '../../enkeltindberetning/models/controlling-person.model';
import { EnkeltindberetningModel } from "../../enkeltindberetning/models/enkeltindberetning.model";
import { IdNumberModel } from "../../enkeltindberetning/models/id-number.model";
import { IndividualAccountHolderModel } from "../../enkeltindberetning/models/individual-account-holder.model";
import { OrganisationAccountHolderModel } from "../../enkeltindberetning/models/organisation-account-holder.model";
import { PaymentModel } from "../../enkeltindberetning/models/payment.model";
import { CountryModel } from "../models/country-model";
import { getEnkeltingberetningModel } from "./session-storage-utils";

export function getAndParseEnkeltingberetningModel(): EnkeltindberetningModel {
    const object = JSON.parse(getEnkeltingberetningModel());
    if (object == null) {
        return new EnkeltindberetningModel();
    }
    return parseEnkeltingberetningModel(object);
}

export function parseEnkeltingberetningModel(object: any): EnkeltindberetningModel {
    const model = Object.assign(new EnkeltindberetningModel(), object);
    const objectAccount = object.accountReport;
    model.accountReport = Object.assign(new AccountReportModel(), objectAccount);
    model.address = Object.assign(new AddressModel(), object.address);

    model.accountReport.individualAccountHolder =
        mapAccountHolder(objectAccount.individualAccountHolder, IndividualAccountHolderModel);
    model.accountReport.organisationAccountHolder =
        mapAccountHolder(objectAccount.organisationAccountHolder, OrganisationAccountHolderModel);
    model.accountReport.payments = Object.assign(new PaymentModel(), objectAccount.payments);

    model.accountReport.controllingPersons = [];
    for (const cp of objectAccount.controllingPersons) {
        const cpModel: ControllingPersonModel = Object.assign(new ControllingPersonModel(), cp);
        cpModel.individualAccountHolder = mapAccountHolder(cp.individualAccountHolder, IndividualAccountHolderModel);
        model.accountReport.controllingPersons.push(cpModel);
    }

    return model;
}

function mapAccountHolder<T extends AccountHolderModel>(accountHolder: any, c: new() => T): T {
    const acctHolder: T = Object.assign(new c(), accountHolder);

    acctHolder.addresses = [];
    for (const addr of accountHolder.addresses) {
        acctHolder.addresses.push(
            Object.assign(new AddressModel(), addr)
        );
    }

    acctHolder.idNumbers = [];
    for (const idNumber of accountHolder.idNumbers) {
        acctHolder.idNumbers.push(
            Object.assign(new IdNumberModel(), idNumber)
        );
    }

    return acctHolder;
}

export function mapCountries(object: any): CountryModel[] {
    return object.countries.map((country: any) => Object.assign(new CountryModel(), country));
}
