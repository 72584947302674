export enum OrganisationTypesCrs {
    CRS101 = 'CRS101',
    CRS102 = 'CRS102',
    CRS103 = 'CRS103'
}

export enum OrganisationTypesFatca {
    FATCA102 = 'FATCA102',
    FATCA103 = 'FATCA103',
    FATCA104 = 'FATCA104'
}

export const organisationTypesCrs = [
    { "value": OrganisationTypesCrs.CRS101, "key": "Aktionær" },
    { "value": OrganisationTypesCrs.CRS102, "key": "Ejer" },
    { "value": OrganisationTypesCrs.CRS103, "key": "Selskab" }
];

export const organisationTypesFatca = [
    { "value": OrganisationTypesFatca.FATCA102, "key": `${OrganisationTypesFatca.FATCA102} (Passive NFFE with substantial U.S. owner(s))` },
    { "value": OrganisationTypesFatca.FATCA103, "key": `${OrganisationTypesFatca.FATCA103} (Non-Participating FFI)` },
    { "value": OrganisationTypesFatca.FATCA104, "key": `${OrganisationTypesFatca.FATCA104} (Specified U.S. Person)` }
];

export const organisationTypesWithControllingPerson = [
    `${OrganisationTypesCrs.CRS101}`, `${OrganisationTypesFatca.FATCA102}`
];
