import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FiloverfoerselIndberetComponent } from './components/filoverfoersel-indberet/filoverfoersel-indberet.component';
import { FiloverfoerselModtagetComponent } from './components/filoverfoersel-modtaget/filoverfoersel-modtaget.component';
import { filuploadRoutes } from './filoverfoersel.routing';
import { FiloverfoerselModel } from "./models/filoverfoersel.model";
import { FiluploadFactory } from "./services/filupload/filupload.factory";

@NgModule({
    imports: [
        CommonModule,
        filuploadRoutes,
        SharedModule
    ],
    declarations: [
        FiloverfoerselIndberetComponent,
        FiloverfoerselModtagetComponent
    ],
    exports: [
        FiloverfoerselIndberetComponent,
        FiloverfoerselModtagetComponent,
        RouterModule
    ],
    providers: [
        FiloverfoerselModel,
        FiluploadFactory
    ]
})

export class FiloverfoerselModule {
}
