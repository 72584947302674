import { Fejl } from "../../../shared/models/fejl.model";
import { validGiin } from "../../../shared/utils/validation-utils";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";

export class EnkeltIndberetningValidatorFatca {

    static validate(model: EnkeltindberetningModel): Fejl[] {
        const errors: Fejl[] = [];
        if(!validGiin(model.giin)) {
            errors.push(new Fejl("NULINDBERETNING.INDBERET.GIIN.FEJL.FORMAT_IKKE_OVERHOLDT"));
        }
        return errors;
    }
}
