import { Fejl } from "../../shared/models/fejl.model";

export class ResCountryCodeValidator {

    static validate(resCountryCodes: string[]): Fejl[] {
        if (resCountryCodes.length === 0) {
            return [new Fejl("ENKELTINDBERETNING.KONTOEJER.FEJL.NO_RESIDENTIAL_COUNTRY")];
        }
        return [];
    }
}
