export class NulindberetningModel {
    accept:boolean;
    reportingYear:string;
    giin:string;
    oprettelsesid:number;

    constructor() {
        this.init();
    }

    init() {
        this.accept = false;
        this.reportingYear = "";
        this.giin = null;
        this.oprettelsesid = null;
    }
}
