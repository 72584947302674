import { EventEmitter, Injectable, Output } from "@angular/core";
import { Fejl } from "../../models/fejl.model";
import { nyStandardFejl } from "../../utils/fejl-utils";

@Injectable()
export class ErrorService {
    private errors: Fejl[] = [];
    @Output() newErrors: EventEmitter<any> = new EventEmitter();

    reset() {
        this.errors = [];
        this.newErrors.emit([]);
    }

    //a new instantiation of the array is necessary for change to be detected. I.e. push() does not suffice.
    tilfoejFejl(fejl: Fejl[]) {
        this.errors = this.errors.concat(fejl);
        this.newErrors.emit(fejl);
        this.focusInTop();
    }

    resetOgTilfoejFejlbesked(fejlbesked: string) {
        this.reset();
        this.tilfoejFejl([new Fejl(fejlbesked)]);
    }

    tilfoejStandardFejl() {
        this.tilfoejFejl([nyStandardFejl()]);
        this.focusInTop();
    }

    derErFejl(): boolean {
        return this.errors.length > 0;
    }

    alleFejl(): Fejl[] {
        const returnList: Fejl[] = [];
        for (const fejl of this.errors) {
            returnList.push(new Fejl(fejl.fejltekst, fejl.fejlkode));
        }
        return returnList;
    }

    indeholderFejlMedKode(kode:string):boolean {
        let fandtKode = false;
        for (const f of this.errors) {
            if (f.fejlkode === kode) {
                fandtKode = true;
            }
        }
        return fandtKode;
    }

    focusInTop() {
        window.scrollTo(0, 0);
    }
}
