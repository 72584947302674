export class PaymentModel {
    dividendsAmount: string;
    dividendsCurrencyCode: string = "";

    interestAmount: string;
    interestCurrencyCode: string = "";

    redemptionAmount: string;
    redemptionCurrencyCode: string = "";

    otherAmount: string;
    otherCurrencyCode: string = "";
}
