import { Directive, Input } from '@angular/core';

/*
 Functionality so the user by tapping can skip the navigation, see REN-2371
 */
@Directive({selector: '[skatOnLinkOnEnter]'})
export class OnLinkOnEnterDirective {

    @Input() skatOnLinkOnEnter: Function;
    @Input() elementId: string;

    constructor() {
    }

    ngOnInit() {
        const func:Function = this.skatOnLinkOnEnter;

        const link = document.getElementById(this.elementId);
        link.addEventListener("keyup", function (event:any) {
            if (event.keyCode === 13) {
                if (func) {
                    func();
                }
            }
        });
    }
}
