import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { Fejl } from "../../../shared/models/fejl.model";
import { genererUrlTilAccountReports, genererUrlTilSletAccountReport } from '../../../shared/utils/url-utils';
import { KontoejerPagedResultModel } from "../../models/kontoejerPagedResult.model";
import { OversigtFilterValuesModel } from "../../models/oversigt-filter-values.model";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class KontoejereService {

    searchResult: Subject<KontoejerPagedResultModel> = new Subject<KontoejerPagedResultModel>();
    errorResponses: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    /**
     * Henter kontooverblikket.
     * @param period perioden for hvilken data skal hentes
     * @param offset paginerings-offset
     * @param pageSize ønsket paginerings-pagesize.
     * @param sortBy (valgfri) navn på kolonnen, der skal sorteres efter
     * @param ascending (valgfri) om der skal sorteres i stigende rækkefølge
     */
    public hentKontoejere(filterCriterias: OversigtFilterValuesModel, offset: number=0, pageSize: number=20, sortBy: string = null, ascending: boolean = false) {
        const path: string = genererUrlTilAccountReports(filterCriterias, offset, pageSize, sortBy, ascending);

        this.http.get<any>(path, httpOptions)
            .subscribe(
                (result: any) => {
                    this.extractData(result);
                },
                error => {
                    this.handleError();
                }
            );
    }

    public sletAccountReports(reportingYear: string, arDocRefIds:string[]) {
        const path: string = genererUrlTilSletAccountReport();
        const body = {
            "reportingYear" : reportingYear,
            "arDocRefIds" : arDocRefIds
        };
        this.http.post(path, body, httpOptions)
            .map(res => {
                return res;
            })
            .subscribe(
                () => {},
                error => {
                    this.handleError();
                }
            );
    }

    private extractData(body: any) {
        if (body) {
            this.searchResult.next(body);
        } else {
            this.errorResponses.next(new Fejl('KONTOEJEROVERSIGT.FEJL.SERVICEKALD'));
        }
    }

    private handleError() {
        this.errorResponses.next(new Fejl('KONTOEJEROVERSIGT.FEJL.SERVICEKALD'));
    }
}
