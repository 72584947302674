import { RouterModule } from "@angular/router";
import { HistorikComponent } from "./components/historik/historik.component";
import { KontoejereOversigtComponent } from "./components/kontoejere-oversigt/kontoejere-oversigt.component";

export const tidligereIndberetningerRouting = RouterModule.forChild([
    {
        path: 'tidligere-indberetninger',
        component: HistorikComponent
    },
    {
        path: 'tidligere-indberetninger/historik',
        component: HistorikComponent
    },
    {
        path: 'tidligere-indberetninger/oversigt',
        component: KontoejereOversigtComponent
    },
]);
