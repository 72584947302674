import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { EnkeltindberetningIndberetningComponent } from "./components/enkeltindberetning-indberetning/enkeltindberetning-indberetning.component";
import { EnkeltindberetningKontoejerComponent } from "./components/enkeltindberetning-kontoejer/enkeltindberetning-kontoejer.component";
import { EnkeltindberetningKontrollerendePersonComponent } from "./components/enkeltindberetning-kontrollerende-person/enkeltindberetning-kontrollerende-person.component";
import { EnkeltindberetningModtagetComponent } from "./components/enkeltindberetning-modtaget/enkeltindberetning-modtaget.component";
import { AddressComponent } from "./components/partials/address/address.component";
import { NavbarComponent } from "./components/partials/navbar/navbar.component";
import { PersonComponent } from './components/partials/person/person.component';
import { enkeltRoutes } from "./enkeltindberetning.routing";
import { EnkeltindberetningModel } from "./models/enkeltindberetning.model";

@NgModule({
    imports: [
        CommonModule,
        enkeltRoutes,
        SharedModule
    ],
    declarations: [
        EnkeltindberetningKontoejerComponent,
        EnkeltindberetningIndberetningComponent,
        EnkeltindberetningKontrollerendePersonComponent,
        EnkeltindberetningModtagetComponent,
        NavbarComponent,
        AddressComponent,
        PersonComponent
    ],
    exports: [
        RouterModule,
        EnkeltindberetningKontoejerComponent,
        EnkeltindberetningIndberetningComponent,
        EnkeltindberetningKontrollerendePersonComponent,
        EnkeltindberetningModtagetComponent,
        NavbarComponent
    ],
    providers: [
        EnkeltindberetningModel
    ]
})
export class EnkeltindberetningModule {
}
