import { RouterModule, Routes } from "@angular/router";
import { ForsideComponent } from "./forside/forside.component";
import { FejlsideComponent } from "./shared/components/fejlside/fejlside.component";

export const appRoutingProviders: any[] = [];

export const routes: Routes = [
    {
        path: '',
        component: ForsideComponent
    },
    {
        path: 'forside',
        component: ForsideComponent
    },
    {
        path: 'fejlside',
        component: FejlsideComponent
    },
    {
        path: 'filupload',
        loadChildren: 'app/filoverfoersel/filoverfoersel.module#FiloverfoerselModule',
        pathMatch: 'full'
    },
    {
        path: 'nulindberetning',
        loadChildren: 'app/nulindberetning/nulindberetning.module#NulindberetningModule',
        pathMatch: 'full'
    },
    {
        path: 'tidligere-indberetninger',
        loadChildren: 'app/tidligere-indberetninger/tidligere-indberetninger.module#TidligereIndberetningerModule',
        pathMatch: 'full'
    },
    {
        path: 'enkeltindberetning',
        loadChildren: 'app/enkeltindberetning/enkeltindberetning.module#EnkeltindberetningModule',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: ForsideComponent
    },
];

export const appRouting = RouterModule.forRoot(routes, {useHash: true});
