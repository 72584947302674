import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from "rxjs";
import 'rxjs/Rx';

@Injectable()
export class FiluploadFactory {

    onError: Subject<[any, any, any]> = new Subject<[any, any, any]>();
    onSuccess: Subject<[any, any, any]> = new Subject<[any, any, any]>();

    constructor(){}

    getXMLHttpRequest(): XMLHttpRequest {
        return new XMLHttpRequest();
    }

    createXMLHttpRequest(progressEvent: EventEmitter<{}>): XMLHttpRequest {

        const xhr = this.getXMLHttpRequest();
        xhr.withCredentials = true;

        xhr.upload.onprogress = (event) => {
            const progress = Math.round(event.lengthComputable ? event.loaded * 100 / event.total : 0);
            progressEvent.emit(progress);
        };

        xhr.onload = () => {
            if (xhr.readyState === xhr.DONE) {
                const headers = this.parseHeaders(xhr.getAllResponseHeaders());
                const response = xhr.response;
                this.isSuccessCode(xhr.status) ?
                    this.onSuccess.next([response, xhr.status, headers])
                     : this.onError.next([response, xhr.status, headers]);
            }
        };

        xhr.onerror = () => {
            const headers = this.parseHeaders(xhr.getAllResponseHeaders());
            const response = xhr.response;
            this.onError.next([response, xhr.status, headers]);
        };

        xhr.onabort = () => {
            const headers = this.parseHeaders(xhr.getAllResponseHeaders());
            const response = xhr.response;
            this.onError.next([response, xhr.status, headers]);
        };

        return xhr;
    }

    isSuccessCode(status: any) {
        return (status >= 200 && status < 300) || status === 304;
    }

    parseHeaders(headers: any) {
        const parsed: any = {};
        let key: any, val: any, i: any;

        if (!headers) {
            return parsed;
        }

        headers.split('\n').map((line: any) => {
            i = line.indexOf(':');
            key = line.slice(0, i).trim().toLowerCase();
            val = line.slice(i + 1).trim();

            if (key) {
                parsed[key] = parsed[key] ? parsed[key] + ', ' + val : val;
            }
        });
        return parsed;
    }
}
