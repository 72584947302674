import { getIndberetningstype } from "../utils/session-storage-utils";

const FATCA:string = "fatca";
const CRS:string = "crs";

export class IndberetningstypeAwareness  {

    // Please ignore "Method can be static" - they in fact can't since HTML components can't interact with static methods!
    erFatca():boolean {
        return IndberetningstypeAwareness.statics.erFatca();
    }

    erCrs():boolean {
        return IndberetningstypeAwareness.statics.erCrs();
    }

    uppercaseTitle():string {
        return IndberetningstypeAwareness.statics.uppercaseTitle();
    }

    /**
     * Dette er lidt et hack for at tillade at både component template og øvrige klasser der kalder direkte på statics, kan benytte metoderne.
     * En template har kun adgang til class-members, og static metoder vil således ikke kunne kaldes fra en template!
     * @type {{erFatca: (() => boolean); erCrs: (() => boolean); uppercaseTitle: (() => string); new() => {}}}
     */
    static statics = class {
        static erFatca() {
            return getIndberetningstype().toLowerCase() === FATCA;
        }
        static erCrs():boolean {
            return getIndberetningstype().toLowerCase() === CRS;
        }

        static uppercaseTitle():string {
            return getIndberetningstype() ? getIndberetningstype().toUpperCase() : "";
        }
    };
}
