export class FiloverfoerselModel {
    fil:File;
    aarstal:string;
    oprettelsesid:number;

    constructor() {
        this.init();
    }

    init() {
        this.fil = null;
        this.aarstal = null;
        this.oprettelsesid = null;
    }
}
