import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { genererIndberetningsaarListe } from "../../../shared/utils/calculations-utils";
import { NulindberetningModel } from "../../models/nulindberetning.model";
import { NulindberetningService } from "../../services/nulindberetning/nulindberetning.service";

@Component({
    templateUrl: './nulindberetning-indberet.component.html',
    providers: [NulindberetningService]
})
export class NulindberetningIndberetComponent extends IndberetningstypeAwareness {

    model: NulindberetningModel;
    errors: ErrorService;
    spinnerTekst: string = "";
    muligeIndberetningsaar = genererIndberetningsaarListe(true);

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private nulindberetningModel: NulindberetningModel,
                private errorService: ErrorService,
                private service: NulindberetningService,
                private translationService: TranslationService,
                private titleService: TitleService) {
        super();
        this.model = this.nulindberetningModel;
        this.errors = errorService;
    }

    ngOnInit() {
        this.errors.reset();
        this.subscribePaaSuccess();
        this.subscribePaaFejl();
        this.translationService.environmentAwareTranslate('NULINDBERETNING.INDBERET.SPINNER').subscribe((res: string) => {
            this.spinnerTekst = res;
        });
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    indberet() {
        this.errors.reset();
        page.showLoader(this.spinnerTekst);
        this.service.nulIndberet(this.model);
    }

    subscribePaaSuccess() {
        this.service.indberetningCompleted.subscribe(
            (svar:any) => {
                page.hideLoader();
                this.router.navigate(['/nulindberetning/modtaget']);
            }
        );
    }

    subscribePaaFejl() {
        this.service.indberetningCompletedWithErrors.subscribe(
            (fejl:Fejl[]) => {
                page.hideLoader();
                if (fejl && fejl.length > 0) {
                    this.errors.tilfoejFejl(fejl);
                }
            }
        );
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('NULINDBERETNING.INDBERET.OVERSKRIFT');
    }
}
