import { Injectable } from "@angular/core";
import { v4 as uuid } from 'uuid';
import { getAndParseEnkeltingberetningModel } from "../../../shared/utils/mapping-utils";
import {
    getErSupplerende, getFIForSupplerende,
    getMsgRef,
    getPligtig, getReportingYear, setEnkeltindberetningModel, setErSupplerende, setFIForSupplerende, setMsgRef,
    setReportingYear
} from "../../../shared/utils/session-storage-utils";
import { isDefined } from "../../../shared/utils/text-utils";
import { EnkeltindberetningModel } from "../../models/enkeltindberetning.model";

@Injectable()
export class EnkeltindberetningFlowService {

    mapOfFI: any = {};
    listOfFI: string[] = [];
    currentlyInitiel: boolean;

    constructor() {
        this.currentlyInitiel = true;
    }

    init() {
        this.mapOfFI = {};
        this.listOfFI = [];
    }

    get reportingYear(): string {
        const year = getReportingYear();
        return year === null ? "" : year;
    }

    set reportingYear(year: string) {
        setReportingYear(year);
    }

    get messageRefIdPrefix(): string {
        return this.reportingYear !== "" ? this.constructMsgRefIdPrefix() : "";
    }

    get messageRefIdValue(): string {
        const messageRef = getMsgRef();
        return messageRef === null ? "" : messageRef;
    }

    set messageRefIdValue(val: string) {
        setMsgRef(val);
    }

    //Decides if it is OECD0 or OECD1
    get supplerende(): boolean {
        return getErSupplerende();
    }

    set supplerende(val: boolean) {
        setErSupplerende(val);
    }

    get selectedFi(): string {
        const FI = getFIForSupplerende();
        return FI === null ? "" : FI;
    }

    set selectedFi(val: string) {
        setFIForSupplerende(val);
    }

    get initielModel(): EnkeltindberetningModel {
        return getAndParseEnkeltingberetningModel();
    }

    getCurrentModel(): EnkeltindberetningModel {
        if (this.supplerende) {
            this.currentlyInitiel = false;
            if (this.selectedFi === "" || !isDefined(this.mapOfFI)) {
                return new EnkeltindberetningModel();
            } else {
                return this.mapOfFI.get(this.selectedFi);
            }
        } else {
            this.currentlyInitiel = true;
            return this.initielModel;
        }
    }

    saveInitielModel(model: EnkeltindberetningModel) {
        if (this.currentlyInitiel) {
            setEnkeltindberetningModel(model);
        }
    }

    constructDocRefIdPrefix(erFatca: boolean, postfix: string, giin: string) {
        if (erFatca) {
            return giin + "." + getPligtig() + ".";
        } else {
            return "DK" + this.reportingYear + getPligtig() + postfix;
        }
    }

    constructMsgRefIdPrefix() {
        return "DK" + this.reportingYear + "DK" + getPligtig();
    }

    static constructUUID() {
        return uuid();
    }
}
